import React, { useState } from "react";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { DebounceInput } from 'react-debounce-input';
import { useParams, useLocation } from 'react-router-dom';
import Header from "../Header";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import placeholderImg from './placeholder-image.jpg';
import SensorTypesSelect from "./SensorTypesSelect";
import { handleApiError } from "../functions/apiErrorHandling";
import LocationMap from "../Common/LocationMap";
import Location from "../Common/Location";
import NetworksSelect from "./NetworkSelect";
import NodeSelect from "../Common/NodeSelect";
import NetworkDisabled from "./NetworkDisabled";
import QRCode from "react-qr-code";
import SendDownlink from "./SendDownlink";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ShowNotification from "./ShowNotification";
import ToastContainer from 'react-bootstrap/ToastContainer';
import DeleteButton from "../Common/DeleteButton";
import SensorRepository from "../../Repositories/SensorRepository";
import Tabs from "./Tabs";
import useAsyncEffect from "../../Extensions/React/AsyncEffect/UseAsyncEffect";
import AsyncPageBody from "../Common/AsyncPageBody";

const { REACT_APP_PROVISION_RELATIVE_URL } = process.env;
const REACT_APP_PROVISION_URL = window.location.origin + REACT_APP_PROVISION_RELATIVE_URL;

type Sensor = {
  eui, join_eui, application_key, type, active, name, description, city, location, network, node, third_party_data
}

const defaultSensor = {
  eui: null,
  join_eui: null,
  application_key: null,
  type: null,
  active: false,
  name: null,
  description: null,
  city: null,
  location: null,
  network: null,
  node: null,
  third_party_data: null
}

const Sensor = () => {
  const [sensor, setSensor] = useState<Sensor>(defaultSensor);
  const [location, setLocation] = useState(null);
  const [file, setFile] = useState("");
  var { sensorId } = useParams();
  const { search } = useLocation();
  const urlQuery = new URLSearchParams(search);
  const [updateError, setUpdateError] = useState("");
  const [showDownlink, setShowDownlink] = useState(false);
  const handleCloseDownlink = () => setShowDownlink(false);
  const handleShowDownlink = () => setShowDownlink(true);
  const [errorNotification, setErrorNotification] = useState(false)

  const asyncEffectState = useAsyncEffect({fetchAsync: (abortSignal) => {
    async function getSensor(sensorId) {
        const response = await SensorRepository.Create().getSensor(sensorId, abortSignal);
        setSensor(response.data);
        setLocation(getLocationArray(response.data.location));
        if (response.data.img != null) {
          setFile(response.data.img);
        } else {
          setFile(placeholderImg);
        }
    }

    return getSensor(sensorId);
  }, deps: [sensorId]});

  const provider = new OpenStreetMapProvider();
  
  function doSearchAddress(value) {
    console.log("searching for value:" + JSON.stringify(value))
    provider.search({ query: value }).then(function (result) {
      console.log("openstreetmap result" + JSON.stringify(result))
      if (result.length > 0) {
        const { x, y, label } = result[0];
        setLocation({ lat: y, lng: x, zoom: 14 });
        setSensor(prevSensor => ({ ...prevSensor, location: y + "," + x }));
      }
    });
  }
  
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    console.log(id, value)
    switch (id) {
      case 'location':
        console.log("setting location to: " + JSON.stringify(value))
        setLocation(getLocationArray(value));
        break;
      case 'city':
        doSearchAddress(value);
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: value,
        }));
        break;
      case 'active':
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: !(value === 'Active'),
        }));
        break;

      default:
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: value,
        }));
    }
  };

  const handleDelete = (event) => {
    SensorRepository.Create().deleteSensor(sensorId)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
          setErrorNotification(true)
        }
      );
  }

  const handleImageChange = (e) => {
    setSensor(prevSensor => ({ ...prevSensor, img: e.target.files[0] }));
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = event => {
    event.preventDefault();
    
    SensorRepository.Create().updateSensor(sensor)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.log("updatesensor failed")
          console.error(error);
          handleApiError(error, setUpdateError)
          setErrorNotification(true)
        }
      );
  };

  const getLocationArray = (locationstr) => {
    const [lat, lng] = String(locationstr).split(',');
    if (Number.isNaN(Number.parseFloat(lat)) || Number.isNaN(Number.parseFloat(lng))) {
      return null;
    } else {
      return { lat: +lat, lng: +lng, zoom: 14 };
    }
  };

  const navigate = useNavigate();

  return (
    <div>
      <Header>Sensor {sensorId || ''}</Header>
      <Tabs eui={sensorId} />
      <AsyncPageBody state={asyncEffectState}>
        <form onSubmit={handleSubmit} >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">
            <Accordion>
              <Accordion.Item eventKey="0">
                <Accordion.Header>Advanced Settings</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="mb-3" >
                    <Form.Label>EUI</Form.Label>
                    <Form.Control type="eui" placeholder="XXXXXXXXXXXXXXXX"
                      id="eui" required value={sensor.eui || ''} name="eui" readOnly={true}
                      disabled />
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Join EUI</Form.Label>
                    <Form.Control type="join_eui"
                      onChange={handleInputChange} name="join_eui"
                      maxLength={16}
                      placeholder="XXXXXXXXXXXXXXXX" id="join_eui"
                      readOnly={true}
                      disabled
                      value={sensor.join_eui || ''} />
                    <Form.Control.Feedback type="invalid">
                      {updateError["join_eui"]}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>App Key</Form.Label>
                    <Form.Control type="application_key"
                      onChange={handleInputChange} name="application_key"
                      maxLength={32}
                      isInvalid={updateError["application_key"]}
                      placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" id="application_key" required value={sensor.application_key || ''} />
                    <Form.Control.Feedback type="invalid">
                      {updateError["application_key"]}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <SensorTypesSelect changeHandler={handleInputChange} defaultValue={sensor.type}></SensorTypesSelect>
                  <Form.Group className="mb-3" >


                    {sensor.active
                      ? <NetworkDisabled sensor={sensor} />
                      : <NetworksSelect changeHandler={handleInputChange} defaultValue={sensor.network}></NetworksSelect>
                    }
                    <NodeSelect changeHandler={handleInputChange} defaultValue={sensor.node}></NodeSelect>

                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Loriot Data</Form.Label>
                    <Form.Control as="textarea"
                      rows={3}
                      name="third_party_data"
                      value={sensor.third_party_data}
                      disabled />
                  </Form.Group>

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="col-sm-8">
              <p>
                <Button
                  className="form-control"
                  id="active"
                  variant={(sensor.active ? "success" : "danger")}
                  onClick={handleInputChange}
                  value={sensor.active ? "Active" : "Not Active"}
                >{sensor.active ? "Active" : "Not Active"}</Button>
              </p>
              <p>
                <input
                  className="form-control"
                  type="text"
                  placeholder='Name'
                  id='name'
                  value={sensor.name || ''}
                  onChange={handleInputChange}
                  required
                />
              </p>
              <p>
                <textarea
                  className="form-control"
                  rows={3}
                  placeholder='Description'
                  id='description'
                  value={sensor.description || ''}
                  onChange={handleInputChange}
                  required
                />
              </p>
              <Row>
                <Col>
                  <Form.Label htmlFor="city" className="col-sm-2 col-form-label col-form-label-sm">Address</Form.Label>
                </Col>
                <Col>

                  <DebounceInput
                    className="form-control  form-control-sm"
                    label='address'
                    minLength={2}
                    debounceTimeout={500}
                    type="text"
                    placeholder='address'
                    id='city'
                    value={sensor.city || ''}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>

              <p>
                <Location handleInputChange={handleInputChange} device={sensor} />
              </p>

            </div>
            <Col>
              <Row className="mb-3">

                <img src={file} style={{ width: '300px' }} />
              </Row>
              <Row>
                <input
                  className="form-control col-3"
                  type="file"
                  id="img"
                  accept="image/png, image/jpeg"
                  onChange={handleImageChange}
                />
              </Row>
              <br />
              <Row>
                <QRCode style={{ height: "auto", maxWidth: "100px", width: "100%" }} value={REACT_APP_PROVISION_URL + sensor.eui + "/"} href={REACT_APP_PROVISION_URL + sensor.eui + "/"}></QRCode>

              </Row>
            </Col>
          </div>
          <p >
            <ToastContainer
              className="p-3"
              position="middle-center"
              style={{ zIndex: 1 }}>
              <ShowNotification
                show={errorNotification}
                setShow={setErrorNotification}
                timeout={3000}
                message={<></>}>
                <div className="general-error">{updateError["detail"]} </div>

              </ShowNotification>
            </ToastContainer>
          </p>
          <p>
            <Row>
              <Col className=" mb-2" >
                <div className="d-flex justify-content-between">
                  <Col className="start-0">
                    <Button variant="primary" type="submit" value="Update">Update</Button>
                  </Col>
                  <Col className="midddle-0">
                    <Button variant="primary" onClick={handleShowDownlink}>
                      Send Downlink
                    </Button>

                    <SendDownlink eui={sensor.eui} showDownlink={showDownlink} handleClose={handleCloseDownlink} />
                  </Col>
                  <Col className="end-0">
                    <DeleteButton entityName={sensor.name} onDelete={handleDelete} />
                  </Col>
                </div>
              </Col>
            </Row>
          </p>
          <p>
            <Row>
              <div className="flex-grow-1 d-flex flex-col align-items-stretch">
                <LocationMap location={location} setLocation={setLocation} setDevice={setSensor} />
              </div>
            </Row>
          </p>
        </form>
      </AsyncPageBody>
    </div>
  );
}

export default Sensor;
