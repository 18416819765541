import React, { useState, useMemo } from "react";
import { Link } from 'react-router-dom';
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck,faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import UserRepository from "../../Repositories/UserRepository";
import EntityListTableView from "../Common/EntityListTableView";
import useAsyncEffect from "../../Extensions/React/AsyncEffect/UseAsyncEffect";

const Users = () => {
    const [userId, setUserId] = useState("");
    const UserLink = ({ to }) => {
        if (String(userId) != String(to)) {
            return (
                <Link to={`/users/${to}/edit`}><FontAwesomeIcon style={{ color: 'green' }} icon={faCircleCheck} title={"Edit user id " + to}  /></Link>
            )
        } else {
            return (
                <FontAwesomeIcon style={{ color: 'blue' }} icon={faCircleInfo} title={"Info user id" + userId} />
            )
        }
    };

    useAsyncEffect({ fetchAsync: (abortSignal) => {
        async function getUserProfile() {
            UserRepository.Create().getUserProfile(abortSignal)
                .then(
                    (response) => {
                        console.log(response.data)
                        setUserId(response.data.id);
                        console.log(response.data.count);
                    },
                    (error) => {
                        const _event =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                        console.log("getUserProfiles Error:" + JSON.stringify(_event))
                        setUserId("Loading")
                    }
                )
                .catch(
                    (error) => {
                        console.error('There has been a problem with your getuserid operation: ' + error.message);
                    }
                );
        }

        return getUserProfile()
    }, deps: []});

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                size: 20,
               Cell: ({ row,cell }) => (
                    <UserLink to={cell.getValue()}></UserLink>
                ),
            },
            {
                accessorKey: 'username',
                header: 'Username',
            },
            {
                accessorKey: 'first_name',
                header: 'First name',
            },
            {
                accessorKey: 'last_name',
                header: 'Last name',
            },
            {
                accessorKey: 'email',
                header: 'Email',
            },
            {
                id: 'profile__node__name',
                accessorKey: 'profile.node_name',
                header: 'Tenant',
            },
        ],
        [userId],
    );
    
    return (
        <div>
            <Header>Users</Header>
            <Divider />
            <PageBody>
                <EntityListTableView
                    entityNamePlural="Users"
                    entityFetch={(config) => UserRepository.Create().getUsers(config)}
                    columns={columns}
                    getRowId={(row) => row.id}
                    initialSorting={[
                        {
                            id: 'username',
                            desc: false,
                        },
                    ]}
                    deps={[userId]}
                />
                <br />
                <p>
                    <Link to="/users/create/"><Button variant="primary">Create New</Button></Link>
                </p>
            </PageBody>
        </div>
    );
}

export default Users;
