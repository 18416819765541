import { useEffect, useState } from "react";
import { URLSearchParamsInit, useLocation, useSearchParams } from "react-router-dom";
import { ColumnFiltersState, SortingState } from "@tanstack/table-core";
import Pagination from "../../../Libs/HTTP/Pagination";
import PaginatedListState from "./PaginatedListState";

const usePaginatedListState = (defaultInit?: URLSearchParamsInit, initialSorting?: SortingState): PaginatedListState => {
    const [searchParams, setSearchParams] = useSearchParams(defaultInit);
    const { search } = useLocation();
    const urlQuery = new URLSearchParams(search);
    const [pagination, setPagination] = useState(new Pagination());
    const [searchFilter, setSearchFilter] = useState('');
    const [columnFilters, setColumnFilters] = useState(<ColumnFiltersState>[]);
    const [sorting, setSorting] = useState(initialSorting ?? [
        {
            id: 'name',
            desc: false,
        },
    ]);

    useEffect(() => {
        let pageIndex = 0;
        let pageSize = 10;
        
        const searchValue = searchParams.get('search');
        if (searchValue) {
            setSearchFilter(searchValue);
        }
        
        const limitValue = Number(searchParams.get('limit'));
        if (limitValue) {
            pageSize = limitValue;
        }

        const offsetValue = searchParams.get('offset');
        if (offsetValue) {
            pageIndex = parseInt(offsetValue)/pageSize
        }

        setPagination(new Pagination(pageSize, pageIndex));
    }, []);

    const onPageLoad = () => {
        urlQuery.set('search', searchFilter)
        urlQuery.set('offset', (pagination.pageIndex * pagination.pageSize).toString())
        urlQuery.set('limit', pagination.pageSize.toString())
        urlQuery.set('filters', JSON.stringify(columnFilters ?? []))
        setSearchParams(urlQuery)
    };

    return <PaginatedListState>{
        searchFilter,
        setSearchFilter,
        columnFilters,
        setColumnFilters,
        sorting,
        setSorting,
        pagination,
        setPagination,
        setSearchParams,
        onPageLoad
    };
}



export default usePaginatedListState;
