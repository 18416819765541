import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { handleApiError } from "../functions/apiErrorHandling";
import NodeSelect from "../Common/NodeSelect";
import DeleteButton from "../Common/DeleteButton";
import ReactStateObject from "../../Extensions/ReactStateObject";
import NetworkRepository from "../../Repositories/NetworkRepository";

const ApplicationEdit = () => {
  const { state: application, onChange: handleInputChange, setState: setApplication } = ReactStateObject.useState<{id, name, apiurl, token, application_token, node}>();
  var { applicationId } = useParams();
  const [updateError, setUpdateError] = useState("");

  useEffect(() => {
    async function getApplication(applicationId) {
      try {
        const response = await NetworkRepository.Create().getNetwork(applicationId);
        setApplication(response.data);
        console.log(response.data)
      } catch (error) {
        console.log("getApplication Error: " + JSON.stringify(error));
        setApplication([{ "id": "N/A", "name": "N/A", "apiurl": "N/A", "token" : "N/A", "application_token" : "N/A" }]);
      }
    }
    console.log("applicationId=" + applicationId)
    getApplication(applicationId);
  }, []);

  const handleSubmit = event => {
    event.preventDefault();
    console.log(application);
    NetworkRepository.Create().updateNetwork(application)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );
  };

  const handleDelete = (event) => {
    NetworkRepository.Create().deleteNetwork(application.id)
      .then((response) => {
        console.log("entity deleted:", response.data);
        navigate(-1);
      })
      .catch((error) => {
        console.log(error);
        console.error("Error" + JSON.stringify(error));
        handleApiError(error, setUpdateError);
      });
  }

  // setup
  const navigate = useNavigate();
  return (
    <div>
      <Header>Editing: {application.name || 'Undefined'}</Header>

      <Divider width="100%" />
      <PageBody>
        <Form onSubmit={handleSubmit} noValidate >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">

            <div className="col-sm-8">
              <p>
                <p>

                </p>
                <Form.Group>

                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Name'
                    id='name'
                    value={application.name || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["name"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["name"]}
                  </Form.Control.Feedback>
                 
                  <NodeSelect
                    changeHandler={handleInputChange}
                    defaultValue={application.node}
                    updateError={updateError}
                  />
                </Form.Group>                 
                
                <Form.Group hidden={application.apiurl === undefined}>
                  <Form.Label>API Url</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='https://my.api.url'
                    id='apiurl'
                    value={application.apiurl || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["apiurl"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["apiurl"]}
                  </Form.Control.Feedback>
                 

                  <Form.Label>Token</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Token'
                    id='token'
                    value={application.token || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["token"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["token"]}
                  </Form.Control.Feedback>
                 

                  <Form.Label>Application Token</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Application Token'
                    id='application_token'
                    value={application.application_token || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["application_token"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["application_token"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </p>
              <p>
                <Form.Control.Feedback type="invalid">
                  {updateError["version"]}
                </Form.Control.Feedback>
              </p>

              <p>
                {updateError["non_field_errors"] && (
                  <div className="error">
                    {updateError["non_field_errors"]}
                  </div>
                )}
              </p>

              <div className="row">
                <div className="col mb-2" >
                  <div className="d-flex justify-content-between">
                    <div className="col start-0">
                      <Button variant="primary" type="submit" value="Update" >Update</Button>
                    </div>
                    <div className="col end-0">
                      <DeleteButton entityName={application.name} onDelete={handleDelete} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>


      </PageBody>
    </div>
  );
}

export default ApplicationEdit;
