import React from "react";
import { Routes, Route } from "react-router-dom";
import Sensors from './Sensors';
import SensorEdit from './Edit';
import SensorCreate from './Create';
import SensorView from "./View";
import Redirect from "../../Extensions/React/ReactRouter/Redirect";

const SensorRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Sensors/>} />
            <Route path="/create/" element={<SensorCreate/>} />
            <Route path="/:sensorId/*" >
                <Route path="" element={<SensorView/>} />
                <Route path="edit" element={<SensorEdit/>} />
            </Route>

            { /* deprecated */ }
            <Route path="/edit/:sensorId" element={<Redirect to={"/sensors/:sensorId/edit"}/>} />
        </Routes>
    );
}

export default SensorRoutes;
