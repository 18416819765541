import React from "react";
import RegisterForm from "./RegisterForm";
import GatewayRepository from "../../Repositories/GatewayRepository";
import AxiosRequestConfigBuilder from "../../Extensions/Axios/AxiosRequestConfigBuilder"

const GatewayRegisterForm = ({qr}) => {
    const GetOptions = async (inputValue) => {
        const config = AxiosRequestConfigBuilder.create()
            .withPaginationLimitAndOffset(100)
            .withSearch(inputValue)
            .build();

        try {
            const response = await GatewayRepository.Create().getGatewaysList(config);
            const gateways = response.data.results;
            const gatewayOptions = gateways.map((g) => {
                return {
                    key: "gateway_" + g.eui,
                    type: "gateway",
                    typeId: 3,
                    value: g.eui,
                    name: g.name,
                    label: g.name + " (" + g.eui + ")"
                };
            });
            console.log(JSON.stringify(gatewayOptions));
            return gatewayOptions;
        } catch (error) {
            const _event =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            console.log("getGatewaysList Error:" + JSON.stringify(_event));
        }
    };

    return <RegisterForm qr={qr} DeviceTypeName="Gateway" loadOptions={GetOptions} />
};

export default GatewayRegisterForm;
