import React, { useContext, useState } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faSitemap } from '@fortawesome/free-solid-svg-icons';
import NavDropdown from 'react-bootstrap/NavDropdown';
import UserPart from "./Header/UserPart";
import { AppContext } from "../App";
import { Button, DropdownHeader, DropdownItemText, Navbar } from "react-bootstrap";
import NodeSelectField from "./Common/NodeSelectField";

const Header = ({ children }) => {
  const { setToken, parentCallback, userProfile, isDesktopOrLaptop, globalViewNode, setGlobalViewNode } = useContext(AppContext);
  
  const HandleCallBack = (callback) => {
    callback()
  }
  
  const handleLogout = () => {
    console.log('REMOVING TOKEN AND REDIRECTING TO /');
    setToken(false);
  };

  const handleInputChange = (event, node) => {
    setGlobalViewNode(node);
  };

  const updateError = {};
  const globalGroupFilterIsSet = globalViewNode != null && globalViewNode.id > 1;

  return (
    <div style={{display: "flex", flexFlow: "wrap", width: "100%", paddingRight: 18, alignItems: "center"}}>    
      {!isDesktopOrLaptop ? <div className="col-1" onClick={() => HandleCallBack(parentCallback)}>{<h1><FontAwesomeIcon icon={faBars} /></h1>}</div> : ''}
      <div className="col text-md-start">
        {
          isDesktopOrLaptop 
          ? <h1>{(children) ? children : ""}</h1>
          : <h1 style={{fontSize: 20, marginLeft: 18, wordBreak: "break-word"}}>{(children) ? children : ""}</h1>
        }
      </div>
      <div style={{alignItems: "end"}}>
        <h1>
          <h3>
            <Navbar style={{justifyContent: "flex-end"}}>
              <NavDropdown title={
                    <>
                      <Button variant={globalGroupFilterIsSet ? "warning" : "outline-secondary"}>
                        <span style={{ verticalAlign: "middle" }}>
                          <FontAwesomeIcon
                            icon={faSitemap}
                            title={"Global group filter"}
                          />
                        </span>
                        {
                          isDesktopOrLaptop && globalGroupFilterIsSet
                          ? <span style={{fontSize: "8pt", marginLeft: 12, verticalAlign: "middle"}}>{globalViewNode?.path}</span>
                          : ""
                        }                              
                      </Button>
                    </>}
                  style={{color: "white"}}
                  align={{lg: isDesktopOrLaptop ? "end" : "start"}}
                >
                <DropdownHeader>Global group filter</DropdownHeader>
                <DropdownItemText>
                  Current:<br/>
                  <small>{globalGroupFilterIsSet ? globalViewNode?.path : "No filter"}</small>
                </DropdownItemText>
                <NavDropdown.Divider />
                <DropdownItemText>
                  <NodeSelectField
                    changeHandler={handleInputChange}
                    defaultValue={globalViewNode?.id || 1}
                    updateError={updateError}
                    valueKey="id"
                  />
                </DropdownItemText>
                <DropdownItemText>
                  <Button onClick={(event) => handleInputChange({target: {id: "node", value: null}}, null)}>Reset</Button>
                </DropdownItemText>
              </NavDropdown>
              <NavDropdown
                title={<UserPart username={userProfile.username} isDesktopOrLaptop={isDesktopOrLaptop} />}
                align={{lg: isDesktopOrLaptop ? "end" : "start"}}
              >
                <NavDropdown.Item as={Link} to="/userprofile/">Profile</NavDropdown.Item>
                <NavDropdown.Item as={Link} to="/changepassword/">Change Password</NavDropdown.Item>
                <NavDropdown.Divider />
                <NavDropdown.Item onClick={() => handleLogout()}>Logout</NavDropdown.Item>
              </NavDropdown>
            </Navbar>
          </h3>
        </h1>
      </div>
    </div>
  );
}

export default Header;
