import React, { DependencyList, useState } from "react";
import { MRT_ColumnDef, MRT_Row, MRT_RowData, MRT_ShowHideColumnsButton, MRT_ToggleFiltersButton, MRT_ToggleFullScreenButton, MaterialReactTable } from 'material-react-table';
import { VisibilityState, SortingState } from "@tanstack/table-core";
import useAxiosListEffect from "../../Extensions/React/AxiosListEffect/UseAxiosListEffect";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { FileDownload, FilterList } from '@mui/icons-material';
import { Box, Button } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { mkConfig, generateCsv, download } from 'export-to-csv';

const EntityListTableView = ({
    entityNamePlural,
    entityFetch,
    columns,
    columnVisibility,
    initialSorting,
    getRowId,
    onPageLoadCallback = () => {},
    deps
}: {
    entityNamePlural: string,
    entityFetch: (config: AxiosRequestConfig) => Promise<AxiosResponse>,
    columns: MRT_ColumnDef<any, any>[],
    columnVisibility?: VisibilityState,
    initialSorting?: SortingState,
    getRowId: any,
    onPageLoadCallback?: () => void,
    deps?: DependencyList
}) => {
    const axiosListState = useAxiosListEffect(entityFetch, onPageLoadCallback, initialSorting, deps);
    const {
        paginatedListState,
        entities,
        isError,
        isLoading,
        isRefetching,
        rowCount,
        fetchAllRows
    } = axiosListState;
    const {
        searchFilter: globalFilter,
        setSearchFilter: setGlobalFilter,
        columnFilters,
        setColumnFilters,
        sorting,
        setSorting,
        pagination,
        setPagination
    } = paginatedListState;
    const [loadingAllRows, setLoadingAllRows] = useState(false);

    const onPaginationChange = (updater) => {
        setPagination(prevState => updater(prevState));
    }

    initialSorting ??= [
        {
            id: "name",
            desc: true,
        },
    ];

    const filenameFn = () => {
        let entityPart = entityNamePlural.replaceAll(" ", "-");
        let datePart = (new Date()).toISOString();
        datePart = datePart.substring(0, datePart.lastIndexOf('.'));
        datePart = datePart.replaceAll(':', '-');

        return entityPart + "-" + datePart;
    }
    const csvConfig = mkConfig({
        fieldSeparator: ';',
        decimalSeparator: ',',
        useKeysAsHeaders: true,
        quoteStrings: true,
        filename: filenameFn()
    });
    const flattenRowData = (rows: any[]) => rows.map(e => {
        for (let [k, v] of Object.entries(e)) {
            if (v instanceof Object) {
                e[k] = v["id"];
            }
        }
        return e;
    });
    const handleExportRows = (rows: MRT_Row<any>[]) => {
        const rowData = flattenRowData(rows.map((row) => row.original));
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };
    const handleExportAllRows = async () => {
        setLoadingAllRows(true);
        const response = await fetchAllRows();
        setLoadingAllRows(false);
        const rowData = flattenRowData(response.data.results);
        const csv = generateCsv(csvConfig)(rowData);
        download(csvConfig)(csv);
    };

    return (
        <MaterialReactTable
            getRowId={getRowId}
            layoutMode={"grid"}
            data={entities}
            columns={columns}
            enableColumnActions={true}
            enableColumnFilters={true}
            manualFiltering={true}
            manualPagination
            manualSorting
            enablePagination={true}
            enableSorting={true}
            columnFilterDisplayMode="subheader"
            enableBottomToolbar={true}
            enableTopToolbar={true}
            muiTableBodyRowProps={{ hover: false }}
            rowCount={rowCount}
            initialState={{
                pagination: {
                    pageIndex: 0,
                    pageSize: 10
                },
                showColumnFilters: true,
                sorting: initialSorting,
                columnVisibility: columnVisibility,
                showGlobalFilter: true,
            }}
            onColumnFiltersChange={setColumnFilters}
            onGlobalFilterChange={setGlobalFilter}
            onPaginationChange={onPaginationChange}
            onSortingChange={setSorting}
            state={{
                columnFilters,
                globalFilter,
                isLoading,
                pagination,
                showAlertBanner: isError,
                showProgressBars: isRefetching,
                sorting,
                density: "compact",
            }}
            muiTablePaperProps={{
                elevation: 0,
                sx: {},
            }}
            renderToolbarInternalActions={({table}) => (
                <>
                    <Button
                        onClick={() => setColumnFilters([])}
                        variant="outlined"
                        color="error"
                        title="Clear filters"
                        startIcon={<FilterList />}
                        disabled={columnFilters.length == 0}
                    >
                        Clear
                    </Button>
                    <MRT_ToggleFiltersButton table={table} />
                    <MRT_ShowHideColumnsButton table={table} />
                    <MRT_ToggleFullScreenButton table={table} />
                </>
            )}
            renderTopToolbarCustomActions={({table}) => (
                <Box
                    sx={{
                    display: 'flex',
                    gap: '16px',
                    padding: '8px',
                    flexWrap: 'wrap',
                    }}
                >
                    <LoadingButton
                        disabled={rowCount === 0}
                        //export all rows, including from the next page, (still respects filtering and sorting)
                        onClick={() => handleExportAllRows()}
                        loading={loadingAllRows}
                        loadingPosition="start"
                        variant="outlined"
                        startIcon={<FileDownload />}
                    >
                        <span>Export All ({rowCount})</span>
                    </LoadingButton>
                    <Button
                        disabled={table.getRowModel().rows.length === 0}
                        //export all rows as seen on the screen (respects pagination, sorting, filtering, etc.)
                        onClick={() => handleExportRows(table.getRowModel().rows)}
                        variant="outlined"
                        startIcon={<FileDownload />}
                    >
                        Export Page
                    </Button>

                    
                </Box>
            )}
        />
    );
};

export default EntityListTableView;
