import React, { CSSProperties } from "react";

const Divider = ({color, width}: {color?: string|number, width?: string|number}) => {
    const style: CSSProperties = {
        borderBottom: color,
        width: width
    };

    return (
        <div className="divider-container">
            <div className="border" style={style} />
        </div>
    );
};

export default Divider;
