import React, { useEffect, useState } from "react";
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { DebounceInput } from 'react-debounce-input';
import { useParams, useLocation } from 'react-router-dom';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import placeholderImg from './placeholder-image.jpg';
import { handleApiError } from "../functions/apiErrorHandling";
import LocationMap from "../Common/LocationMap";
import Location from "../Common/Location";
import QRCode from "react-qr-code";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import ToastContainer from 'react-bootstrap/ToastContainer';
import ShowNotification from "../Sensors/ShowNotification";
import GatewayTypesSelect from "./GatewayTypesSelect";
import LorawanNetworkSelect from "./LorawanNetworkSelect";
import NodeSelect from "../Common/NodeSelect";
import DeleteButton from "../Common/DeleteButton";
import GatewayRepository from "../../Repositories/GatewayRepository";
import AsyncPageBody from "../Common/AsyncPageBody";
import useAsyncEffect from "../../Extensions/React/AsyncEffect/UseAsyncEffect";

const { REACT_APP_PROVISION_RELATIVE_URL } = process.env;
const REACT_APP_PROVISION_URL = window.location.origin + REACT_APP_PROVISION_RELATIVE_URL;

type Gateway = {
  eui, name, description, mac, type, lorawan_network, node, active, city, location
}

const defaultGateway: Gateway = {
  eui: null,
  name: null,
  description: null,
  mac: null,
  type: null,
  lorawan_network: null,
  node: null,
  active: false,
  city: null,
  location: null,
}

const Gateway = () => {
  const [gateway, setGateway] = useState<Gateway>(defaultGateway);
  const [location, setLocation] = useState(null);
  const [file, setFile] = useState("");
  var { gatewayId } = useParams();
  const { search } = useLocation();
  const urlQuery = new URLSearchParams(search);
  const [updateError, setUpdateError] = useState("");
  const [errorNotification, setErrorNotification] = useState(false)

  const asyncEffectState = useAsyncEffect({ fetchAsync: (abortSignal) => {
    async function getGateway(gatewayId) {
      {
        const response = await GatewayRepository.Create().getGateway(gatewayId, abortSignal);
        setGateway(response.data);
        console.log("Gateway is: " + gateway.eui)
        console.log(gateway)
        setLocation(getLocationArray(response.data.location));
        if (response.data.img != null) {
          setFile(response.data.img);
        } else {
          setFile(placeholderImg);
        }
      }
    }
    console.log("gatewayID" + JSON.stringify(gatewayId))
    if ((gatewayId == undefined) || (gatewayId.length != 16)) {
      console.log("Search" + search)
      gatewayId = urlQuery.get("eui");
      console.log("gatewayID" + JSON.stringify(gatewayId))
    }

    return getGateway(gatewayId);
  }, deps: [gatewayId]});

  const provider = new OpenStreetMapProvider();

  function doSearchAddress(value) {
    console.log("searching for value:" + JSON.stringify(value))
    provider.search({ query: value }).then(function (result) {
      console.log("openstreetmap result" + JSON.stringify(result))
      if (result.length > 0) {
        const { x, y, label } = result[0];
        setLocation({ lat: y, lng: x, zoom: 14 });
        setGateway(prevGateway => ({ ...prevGateway, location: y + "," + x }));
      }
    });
  }
  
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    console.log("id,value:",id, value)
    switch (id) {
      case 'location':
        console.log("setting location to: " + JSON.stringify(value))
        setLocation(getLocationArray(value));
        break;
      case 'city':
        doSearchAddress(value);
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: value,
        }));
        break;
      case 'active':
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: !(value === 'Active'),
        }));
        break;

      default:
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: value,
        }));
    }
  };

  const handleDelete = (event) => {
    GatewayRepository.Create().deleteGateway(gatewayId)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
          setErrorNotification(true)
        }
      );
  }

  const handleImageChange = (e) => {
    setGateway(prevGateway => ({ ...prevGateway, img: e.target.files[0] }));
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = event => {
    event.preventDefault();
    
    GatewayRepository.Create().updateGateway(gateway)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.log("Recordservice.updategateway failed")
          console.error(error);
          handleApiError(error, setUpdateError)
          setErrorNotification(true)
        }
      );
  };

  const getLocationArray = (locationstr) => {
    const [lat, lng] = String(locationstr).split(',');
    if (Number.isNaN(Number.parseFloat(lat)) || Number.isNaN(Number.parseFloat(lng))) {
      return null;
    } else {
      return { lat: +lat, lng: +lng, zoom: 14 };
    }
  };

  const navigate = useNavigate();

  return (
    <div>
      <Header>Editing EUI: {gateway.eui || ''}</Header>
      <Divider width="100%" />
      <AsyncPageBody state={asyncEffectState}>
        <form onSubmit={handleSubmit} >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">
            <Accordion  >
              <Accordion.Item eventKey="0">
                <Accordion.Header>Advanced Settings</Accordion.Header>
                <Accordion.Body>
                <Form.Group className="mb-3" >
                    <Form.Label>MAC</Form.Label>
                    <Form.Control
                      type="mac"
                      placeholder="aa:bb:cc:aa:bb:cc"
                      onChange={handleInputChange}

                      id="mac"
                      value={gateway.mac || ''}
                      name="mac"
                      maxLength={17}
                      isInvalid={updateError["mac"]}
                      disabled={true}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["mac"]}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>EUI</Form.Label>
                    <Form.Control
                      type="eui"
                      placeholder="XXXXXXXXXXXXXXXX"
                      onChange={handleInputChange}

                      id="eui"
                      value={gateway.eui || ''}
                      name="eui"
                      maxLength={16}
                      isInvalid={updateError["eui"]}
                      disabled={true}
                      required
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["eui"]}
                    </Form.Control.Feedback>
                  </Form.Group>


                  <GatewayTypesSelect changeHandler={handleInputChange} defaultValue={gateway.type}></GatewayTypesSelect>
                  <NodeSelect changeHandler={handleInputChange} defaultValue={gateway.node} disabled={false}></NodeSelect>
                  <LorawanNetworkSelect changeHandler={handleInputChange} defaultValue={gateway.lorawan_network}></LorawanNetworkSelect>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="col-sm-8">
              <p>

                <Button
                  className="form-control"
                  id="active"
                  variant={(gateway.active ? "success" : "danger")}
                  onClick={handleInputChange}
                  value={gateway.active ? "Active" : "Not Active"}
                >{gateway.active ? "Active" : "Not Active"}</Button>
              </p>
              <p>
                <input
                  className="form-control"
                  type="text"
                  placeholder='Name'
                  id='name'
                  value={gateway.name || ''}
                  onChange={handleInputChange}
                  required
                />
              </p>
              <p>
                <textarea
                  className="form-control"
                  rows={3}
                  placeholder='Description'
                  id='description'
                  value={gateway.description || ''}
                  onChange={handleInputChange}
                  required
                />
              </p>

              <Row>
                <Col>
                  <Form.Label form="address" className="col-sm-2 col-form-label col-form-label-sm">Address</Form.Label>
                </Col>
                <Col>

                  <DebounceInput
                    className="form-control  form-control-sm"
                    label='address'
                    minLength={2}
                    debounceTimeout={500}
                    type="text"
                    placeholder='address'
                    id='city'
                    value={gateway.city || ''}
                    onChange={handleInputChange}
                  />
                </Col>
              </Row>


              <Location handleInputChange={handleInputChange} device={gateway} />


            </div>
            <Col>
              <Row className="mb-3">
                <p>
                  <img src={file} style={{ width: '200px' }} />
                </p>
                <p>
                  <input
                    className="form-control col-3"
                    type="file"
                    id="img"
                    accept="image/png, image/jpeg"
                    onChange={handleImageChange}
                  />
                </p>
                <p>                  <QRCode style={{ height: "auto", maxWidth: "200px", width: "100%" }} value={REACT_APP_PROVISION_URL + gateway.eui + "/"}  href={REACT_APP_PROVISION_URL + gateway.eui + "/"}></QRCode>
                </p>
              </Row>
            </Col>
          </div>

          <ToastContainer
            className="p-3"
            position="middle-center"
            style={{ zIndex: 1 }}>
            <ShowNotification
              show={errorNotification}
              setShow={setErrorNotification}
              timeout={3000}
              message={<></>}>
              <div className="general-error">{updateError["detail"]} </div>

            </ShowNotification>
          </ToastContainer>


          <Row>
            <Col className=" mb-2" >
              <div className="d-flex justify-content-between">
                <Col className="start-0">
                  <Button variant="primary" type="submit" value="Update">Update</Button>
                </Col>

                <Col className="end-0">
                  <DeleteButton entityName={gateway.name} onDelete={handleDelete} />
                </Col>
              </div>
            </Col>
          </Row>


          <Row>
            <div className="flex-grow-1 d-flex flex-col align-items-stretch">
              <LocationMap location={location} setLocation={setLocation} setDevice={setGateway} />
            </div>
          </Row>

        </form>
      </AsyncPageBody>
    </div>
  );
}

export default Gateway;
