import React from "react";
import { useEffect } from "react";
import { useParams, useNavigate } from 'react-router-dom';
import QRCodeRepository from '../../Repositories/QRCodeRepository';

const QRRouter = () => {
  var { qr } = useParams();
  const navigate = useNavigate()

  useEffect(() => {
    QRCodeRepository.Create().getQRCodeTargetUrl(qr).then(response => {
      let url = response.data.url;
      if (url != null)
      {
        window.location.replace(url);
      }
      else
      {
        navigate("/qr/"+ qr +"/register")
      }
    });
  }, [qr, navigate]);

  return <></>;
}

export default QRRouter;
