import api from "./api";
import { AxiosRequestConfig } from 'axios';

const getUrlName = (url) => {
    return api.get(url);
};

const getAccounts = (config: AxiosRequestConfig) => {
    return api.get("accounts/", config);
};

const getLorawanNetworks = (searchParams) => {
    return api.get("lorawan_networks/", searchParams);
};

const getVendors = (config: AxiosRequestConfig) => {
    return api.get("vendors/", config);
};

const getFlows = (config: AxiosRequestConfig) => {
    return api.get("flows/", config);
};

export type Credentials = {
    user_id: string
    current_password: string
    new_password1: string
    new_password2: string
}

const changePassword = (credentials: Credentials) => {
    const formData = new FormData();
    console.log(credentials);
    const url = "/change_password/" + credentials.user_id + "/";
    Object.entries(credentials).forEach((entry) => {
        if (entry) {
            const [key, value] = entry;
            console.log("key,value:" + key + "," + value);
            console.log(key, value);
            formData.append(key, value);
        }
    });

    console.log(formData);
    var result = api.putForm(url, formData);
    return result;
};

export type Downlink = {
    payload: string,
    port?: number,
    priority: number,
    confirmed: boolean
}

const postDownlink = (eui, downlink: Downlink) => {
    const formData = new FormData();
    Object.entries(downlink).forEach((entry) => {
        if (entry) {
            const [key, value] = entry;
            console.log("key,value:" + key + "," + value);
            console.log(key, value);
            formData.append(key, value.toString());
        }
    });

    var result = api.post("/sensors/" + eui + "/downlink", formData);
    return result;
};

const RecordService = {
    getAccounts,
    getUrlName,
    getVendors,
    postDownlink,
    changePassword,
    getLorawanNetworks,
    getFlows,
};

export default RecordService;
