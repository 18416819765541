import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBatteryEmpty, faBattery5, faBatteryHalf, faBatteryThreeQuarters, faPlug, faQuestion } from '@fortawesome/free-solid-svg-icons';

const Battery = ({ value }) => {
  const CHARGING = -1;
  const UNABLE_TO_MEASURE = -2;
  if (value === undefined || value === null) {
    return <>
      <span className="fa-layers fa-fw">
        <FontAwesomeIcon style={{ color: 'grey' }} opacity={0.5} icon={faBatteryEmpty} title={"n/a"} />
        <FontAwesomeIcon style={{ color: 'grey' }} icon={faQuestion} transform={{x: 0}} title={"n/a"} />
      </span>
    </>
  }

  if (value == UNABLE_TO_MEASURE) {
    return <>
        <span className="fa-layers fa-fw">
          <FontAwesomeIcon style={{ color: 'grey' }} opacity={0.5} icon={faBatteryEmpty} title={"Unable to measure"} />
          <FontAwesomeIcon style={{ color: 'grey' }} icon={faQuestion} transform={{x: 0}} title={"Unable to measure"} />
        </span>
      </>
  }
  
  if (value == CHARGING) {
    return <FontAwesomeIcon style={{ color: 'green' }} icon={faPlug} title={"Plugged in"} />
  }
  
  const batteryPercentage = value;
  const title = batteryPercentage + "%";
  if (batteryPercentage < 25) {
    return <FontAwesomeIcon style={{ color: 'red' }} icon={faBatteryEmpty} title={title} />
  }
  
  if (batteryPercentage < 50) {
    return <FontAwesomeIcon style={{ color: 'orange' }} icon={faBatteryHalf} title={title} />
  }
  
  if (batteryPercentage < 75) {
    return <FontAwesomeIcon style={{ color: 'green' }} icon={faBatteryThreeQuarters} title={title} />
  }
  
  return <FontAwesomeIcon style={{ color: 'green' }} icon={faBattery5} title={title} />
}

export default Battery;
