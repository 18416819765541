import React from "react";
import { Routes, Route } from "react-router-dom";
import Users from './Users';
import UserEdit from './Edit';
import UserCreate from './Create';
import Redirect from "../../Extensions/React/ReactRouter/Redirect";

const UserRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Users/>} />
            <Route path="/create/" element={<UserCreate/>} />
            <Route path="/:userId/*">
                <Route path="edit" element={<UserEdit/>} />
            </Route>
            
            { /* deprecated */ }
            <Route path="/edit/:userId" element={<Redirect to={"/users/:userId/edit"} />} />
        </Routes>
    );
}

export default UserRoutes;
