import React from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";
import {Row,Col} from 'react-bootstrap'
import QRCodesTable from "./CodesTable";

const QRCodes = ({ batch = null, ...props }) => {
    return (
        <div>
            <Header>QR Codes</Header>

            <Divider />
            <PageBody>
                <QRCodesTable />
                <br />
                    <Row>
                         <Col className=" mb-2" >
                            <div className="d-flex justify-content-between">
                                <Col className="start-0">
                                    <Link to="/qr/codes/create"><Button variant="primary">Create Batch</Button></Link>
                                </Col>
                            </div>
                        </Col>
                    </Row>
            </PageBody>
        </div>
    );
}

export default QRCodes;
