import React  from 'react';
import { useMediaQuery } from 'react-responsive';

const Footer = () => {
    const year = new Date().getFullYear();
    const isDesktopOrLaptop = useMediaQuery({
      query: '(min-width: 800px)'
    })
    return (
      <div className="footer">
        <p>Copyright by Sense Solutions ⓒ {year} - {isDesktopOrLaptop?"Desktop":"Mobile"}</p>
      </div>
    );
  }
  export default Footer;