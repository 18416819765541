import React, { useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import Header from "../Header";
import PageBody from "../PageBody";
import placeholderImg from './placeholder-image.jpg';
import LocationMap from "../Common/LocationMap";
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SensorRepository from "../../Repositories/SensorRepository";
import Tabs from "./Tabs";
import useAsyncEffect from "../../Extensions/React/AsyncEffect/UseAsyncEffect";
import { Container, Table } from "react-bootstrap";
import Battery from "../Common/Battery";
import LastSeen from "../Common/LastSeen";
import TimeAgo from 'javascript-time-ago'
import ReactTimeAgo from 'react-time-ago';
import en from 'javascript-time-ago/locale/en';
import AsyncLoadContainer from "../Common/AsyncLoadContainer";

TimeAgo.addDefaultLocale(en);

type Sensor = {
  eui, join_eui, application_key, type, type__name, active, name, description,
  city, location, network, node, third_party_data, network__name, node__name,
  last_seen, battery_state
}

const defaultSensor = {
  eui: null,
  join_eui: null,
  application_key: null,
  type: null,
  type__name: null,
  active: false,
  name: null,
  description: null,
  city: null,
  location: null,
  network: null,
  network__name: null,
  node: null,
  node__name: null,
  third_party_data: null,
  last_seen: null,
  battery_state: null
}

const SensorView = () => {
  const [sensor, setSensor] = useState<Sensor>(defaultSensor);
  const [location, setLocation] = useState(null);
  const [file, setFile] = useState("");
  var { sensorId } = useParams();
  const { search } = useLocation();

  const asyncEffectState = useAsyncEffect({ fetchAsync:
    (abortSignal) => {
      async function getSensor(sensorId) {
        {
          const response = await SensorRepository.Create().getSensor(sensorId, abortSignal);
          setSensor(response.data);
          setLocation(getLocationArray(response.data.location));
          if (response.data.img != null) {
            setFile(response.data.img);
          } else {
            setFile(placeholderImg);
          }
        }
      }
      
      return getSensor(sensorId);
    },
    deps: [sensorId]});
  
  const getLocationArray = (locationstr) => {
    const [lat, lng] = String(locationstr).split(',');
    if (Number.isNaN(Number.parseFloat(lat)) || Number.isNaN(Number.parseFloat(lng))) {
      return null;
    } else {
      return { lat: +lat, lng: +lng, zoom: 14 };
    }
  };

  const displayBatteryState = (v) => {
    switch (v) {
      case undefined:
      case null:
        return "n/a";
      case -2: return "Unable to measure";
      case -1: return "Plugged-in";
      default: return v + "%";
    }
  }

  return (
    <div>
      <Header>Sensor {sensorId || ''}</Header>
      <Tabs eui={sensorId} />
      <AsyncLoadContainer state={asyncEffectState}>
      <PageBody>
        <Container>
          <Row md="auto">
            <Col>
              <Table striped>
                <thead>
                  <tr><th colSpan={2}>General</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>EUI</td>
                    <td>{sensor.eui}</td>
                  </tr>
                  <tr>
                    <td>Name</td>
                    <td>{sensor.name}</td>
                  </tr>
                  <tr>
                    <td>Description</td>
                    <td>{sensor.description}</td>
                  </tr>
                  <tr>
                    <td>Type</td>
                    <td>{sensor.type__name}</td>
                  </tr>
                  <tr>
                    <td>Application</td>
                    <td>{sensor.network__name}</td>
                  </tr>
                  <tr>
                    <td>Owner</td>
                    <td>{sensor.node__name}</td>
                  </tr>
                </tbody>
              </Table>

              <Table striped>
                <thead>
                  <tr><th colSpan={2}>Status</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Last Seen</td>
                    <td><LastSeen value={sensor.last_seen} /> <ReactTimeAgo date={new Date(sensor.last_seen)} locale="en-GB" /></td>
                  </tr>
                  <tr>
                    <td>Battery</td>
                    <td>
                      <Battery value={sensor.battery_state} /> {displayBatteryState(sensor.battery_state)}
                    </td>
                  </tr>
                </tbody>
              </Table>

              <Table striped>
                <thead>
                  <tr><th colSpan={2}>LoraWAN</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Join EUI</td>
                    <td>{sensor.join_eui}</td>
                  </tr>
                  <tr>
                    <td>App Key</td>
                    <td>{sensor.application_key}</td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table striped>
                <thead>
                  <tr><th colSpan={2}>Location</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td>Address</td>
                    <td>{sensor.city}</td>
                  </tr>
                  <tr>
                    <td>GPS</td>
                    <td>{sensor.location}</td>
                  </tr>
                  <tr>
                    <td colSpan={2} style={{ width: '300px' }}>
                      <LocationMap location={location} setLocation={setLocation} setDevice={setSensor} isDraggable={false} />
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Col>
            <Col>
              <Table striped>
              <thead>
                  <tr><th colSpan={2}>Image</th></tr>
                </thead>
                <tbody>
                  <tr>
                    <td><img src={file} style={{ width: '300px' }} /></td>
                  </tr>
                </tbody>
              </Table>
            </Col>
          </Row>
        </Container>
      </PageBody>
      </AsyncLoadContainer>
    </div>
  );
}

export default SensorView;
