
import { useState } from "react";

export default class ReactStateObject<T extends object>
{
    object: T

    constructor(model = {}) {
      let object = {};
      for (let k in model) {
        object[k] = model[k];
      }

      this.object = object as T;
    }

    with(prop, value) {
      return new ReactStateObject<T>({...this.object, [prop]: value});
    }

    withChange(event) {
      const { id, value } = event.target;
      console.log(id, value)
      return this.with(id, value);
    }

    static useState<T extends object>() {
      const [state, set] = useState(new ReactStateObject<T>());

      return {
        state: state.object,
        onChange: (event) => set(state.withChange(event)),
        setState: (newState) => set(new ReactStateObject<T>(newState))
      };
    }
}
