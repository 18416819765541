import React, { useState } from "react";
import { DebounceInput } from 'react-debounce-input';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import GatewayTypesSelect from "./GatewayTypesSelect";
import NodeSelect from "../Common/NodeSelect";
import { handleApiError } from "../functions/apiErrorHandling";
import LocationMap from "../Common/LocationMap";
import LorawanNetworkSelect from "./LorawanNetworkSelect";
import QRCodeRepository from "../../Repositories/QRCodeRepository";
import { toast } from 'react-toastify';
import GatewayRepository from "../../Repositories/GatewayRepository";

type Gateway = {
  eui, name, description, mac, type, lorawan_network, node, active, city, location
}

const defaultGateway: Gateway = {
  eui: null,
  name: null,
  description: null,
  mac: null,
  type: null,
  lorawan_network: null,
  node: null,
  active: false,
  city: null,
  location: null,
}

const Gateway = () => {
  const [searchParams] = useSearchParams();
  const [gateway, setGateway] = useState<Gateway>({...defaultGateway, ["eui"]: searchParams?.get("eui")});
  const [location, setLocation] = useState(null);
  const [file, setFile] = useState("");
  const [updateError, setUpdateError] = useState([]);  
  const provider = new OpenStreetMapProvider();

  function doSearchAddress(value) {
    provider.search({ query: value }).then(function (result) {
      if (result.length > 0) {
        const { x, y, label } = result[0];
        setLocation({ lat: y, lng: x, zoom: 14 });
        setGateway(prevGateway => ({ ...prevGateway, location: y + "," + x }));
      }
    });
  }

  const handleInputChange = (event) => {
    console.log("Create.js (119):")
    const { id, value } = event.target;
    console.log(id, value)
    switch (id) {
      case 'mac':
        let cleanValue=String(value).replaceAll(":","");
        cleanValue = cleanValue.toUpperCase();
        console.log(cleanValue)
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: cleanValue,
          ["eui"] : String(cleanValue).substring(0,6) + 'FFFE' + String(cleanValue).substring(6,12)
        }));
        break;
      case 'location':
        setLocation(getLocationArray(value));
        break;
      case 'city':
        doSearchAddress(value);
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: value,
        }));
        break;
      case 'active':
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: !(value === 'Active'),
        }));
        break;
      default:
        setGateway((prevGateway) => ({
          ...prevGateway,
          [id]: value,
        }));
    }
  };

  const handleImageChange = (e) => {
    setGateway(prevGateway => ({ ...prevGateway, img: e.target.files[0] }));
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = event => {
    event.preventDefault();

    GatewayRepository.Create().createGateway(gateway)
      .then(async (response) => {
        console.log('Item created:', response.data);
        let qr = searchParams?.get("qr");
        console.log("qr: " + qr);
        if (qr != null) {
            await QRCodeRepository.Create().gatewayCreated(qr, gateway.eui);
        }
        
        toast("Gateway created!", { type: "success", autoClose: 3000 });
        setTimeout(() => navigate("/gateways/" + gateway.eui + "/edit"), 3000);
      })
      .catch(
        error => {
          console.error(error);
          toast("Error creating gateway.", { type: "error" });
          handleApiError(error, setUpdateError)
        }
      );
  };

  const getLocationArray = (locationstr) => {
    const [lat, lng] = String(locationstr).split(',');
    if (Number.isNaN(Number.parseFloat(lat)) || Number.isNaN(Number.parseFloat(lng))) {
      return null;
    } else {
      return { lat: +lat, lng: +lng, zoom: 14 };
    }
  };

  const navigate = useNavigate();

  return (
    <div>
      <Header>New gateway {gateway.eui || ''}</Header>

      <Divider width="100%" color="red" />
      <PageBody>
        <Form noValidate onSubmit={handleSubmit} >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">
            <Accordion defaultActiveKey="0" >
              <Accordion.Item eventKey="0">
                <Accordion.Header>Advanced Settings</Accordion.Header>
                <Accordion.Body>
                <Form.Group className="mb-3" >
                    <Form.Label>MAC</Form.Label>
                    <Form.Control type="mac" placeholder="aa:bb:cc:aa:bb:cc"
                      onChange={handleInputChange}
                      id="mac" required value={gateway.mac} name="mac"
                      maxLength={17}
                      isInvalid={updateError["mac"]}

                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["mac"]}
                    </Form.Control.Feedback>
                  </Form.Group>                  
                  <Form.Group className="mb-3" >
                    <Form.Label>EUI</Form.Label>
                    <Form.Control type="eui" placeholder="XXXXXXXXXXXXXXXX"
                      onChange={handleInputChange}
                      id="eui"
                      required
                      value={gateway.eui}
                      name="eui"
                      maxLength={16}
                      isInvalid={updateError["eui"]}

                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["eui"]}
                    </Form.Control.Feedback>
                  </Form.Group>


                  <GatewayTypesSelect changeHandler={handleInputChange} defaultValue={gateway.type}></GatewayTypesSelect>
                  <NodeSelect changeHandler={handleInputChange} defaultValue={null}></NodeSelect>
                  <LorawanNetworkSelect changeHandler={handleInputChange} defaultValue={gateway.lorawan_network}></LorawanNetworkSelect>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="col-sm-8">
              <p>
                <Button
                  className="form-control"
                  id="active"
                  variant={(gateway.active ? "success" : "danger")}
                  onClick={handleInputChange}
                  value={gateway.active ? "Active" : "Not Active"}
                >{gateway.active ? "Active" : "Not Active"}</Button>
              </p>
              <p>
                <input
                  className="form-control"
                  type="text"
                  placeholder='Name'
                  id='name'
                  value={gateway.name || ''}
                  onChange={handleInputChange}
                  required
                />
              </p>
              <p>
                <textarea
                  className="form-control"
                  rows={3}
                  placeholder='Description'
                  id='description'
                  value={gateway.description || ''}
                  onChange={handleInputChange}
                  required
                />
              </p>

              <div className="row mb-3">
                <label htmlFor="address" className="col-sm-2 col-form-label col-form-label-sm">Address</label>
                <div className="col-sm-10">
                  <DebounceInput
                    className="form-control  form-control-sm"
                    label='address'
                    minLength={2}
                    debounceTimeout={500}
                    type="text"
                    placeholder='address'
                    id='city'
                    value={gateway.city || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <p>-or-</p>
              <div className="row mb-3">
                <label htmlFor="location" className="col-sm-3 col-form-label col-form-label-sm">Coordinates:</label>
                <div className="col-sm-9">
                  <DebounceInput
                    className="form-control form-control-sm"
                    label='location'
                    type="text"
                    placeholder='fx. 55.6928608,12.5992622'
                    id='location'
                    value={gateway.location}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="row mb-3">
                <p>
                  <img src={file} style={{ width: '200px' }} />
                </p>
                <p>
                  <input
                    className="form-control col-3"
                    type="file"
                    id="img"
                    accept="image/png, image/jpeg"
                    onChange={handleImageChange}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col mb-2" >
              <div className="d-flex justify-content-between">
                <div className="col start-0">
                  <Button variant="primary" type="submit" value="Create">Create</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row flex-grow-1 d-flex flex-col align-items-stretch">
            <LocationMap location={location} setLocation={setLocation} setDevice={setGateway} />
            </div>
          </div>
        </Form>
      </PageBody>
    </div>
  );
}

export default Gateway;
