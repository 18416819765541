import React, { useState } from "react";
import { useNavigate } from 'react-router-dom';
import Divider from "../Divider";
import PageBody from "../PageBody";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import ReferenceSelect from "./ReferenceSelect";
import QRCodeRepository from "../../Repositories/QRCodeRepository";
import { handleApiError } from "../functions/apiErrorHandling";
import { toast } from 'react-toastify';

const RegisterForm = ({qr, DeviceTypeName, loadOptions}) => {
    const [device, setDevice] = useState<{typeId, value}>();
    const [updateError, setUpdateError] = useState({});
    const [errorMsg, setErrorMsg] = useState({});
    const navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();

        if (device == null || device == undefined) {
            alert("set error");
            setUpdateError({"select": `No ${DeviceTypeName} selected`});
            return;
        }
        
        QRCodeRepository.Create().register(qr, device.typeId, device.value)
            .then((response) => {
                console.log("Item updated:", response.data);
                toast("QR linked to device - redirecting...", { type: "success", autoClose: 3000 });
                setTimeout(() => navigate("/qr/" + qr), 3000);
            })
            .catch((error) => {
                console.error(error);
                handleApiError(error, setUpdateError, setErrorMsg);
            });
    };

    const handleChange = (device) => setDevice(device);

    return (
        <>
            <Divider width="100%" />
            <PageBody>
                
                <Form onSubmit={handleSubmit}>
                    <div className="row mb-3 form-group gy-4 gx-4 align-items-center">
                        <div className="col-sm-8">
                            <p>
                                <Form.Group>
                                    <ReferenceSelect DeviceTypeName={DeviceTypeName} loadOptions={loadOptions} onChange={handleChange} updateError={updateError["select"]} />
                                </Form.Group>
                            </p>

                            <p>
                                {updateError["detail"] && (
                                    <div className="error">
                                        {updateError["detail"]}
                                    </div>
                                )}
                            </p>

                            <div className="row">
                                <div className="col mb-2">
                                    <div className="d-flex justify-content-between">
                                        <div className="col start-0">
                                            <Button
                                                variant="primary"
                                                type="submit"
                                                value="Update"
                                            >
                                                Link to {DeviceTypeName}
                                            </Button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>

            </PageBody>
        </>
    );
};

export default RegisterForm;
