import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { handleApiError } from "../functions/apiErrorHandling";
import DeleteButton from "../Common/DeleteButton";
import NodeSelect from "../Common/NodeSelect";
import ReactStateObject from "../../Extensions/ReactStateObject";
import NodeRepository from "../../Repositories/NodeRepository";

type Tenant = {
    id, name, description, parent
}

const TenantEdit = () => {
  const { state: tenant, onChange: handleInputChange, setState: setTenant } = ReactStateObject.useState<Tenant>();
  var { tenantId } = useParams();
  const [updateError, setUpdateError] = useState("");

  useEffect(() => {
    async function getTenant(tenantId) {
      try {
        const response = await NodeRepository.Create().getNode(tenantId);
        setTenant(response.data);
        console.log(response.data)
      } catch (error) {
        console.log("getSensor Error: " + JSON.stringify(error));
        setTenant([{ "id": "N/A", "name": "N/A", "parent": "N/A" }]);
      }
    }
    console.log("TenantId=" + tenantId)
    getTenant(tenantId);
  }, [tenantId]);

  const handleSubmit = event => {
    event.preventDefault();
    console.log(tenant);
    NodeRepository.Create().updateTenant(tenant)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );
  };

  const handleDelete = (event) => {
    NodeRepository.Create().deleteTenant(tenant.id)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.log(error);
          console.error("Error" + JSON.stringify(error));
          handleApiError(error, setUpdateError)
        }
      );
  }

  // setup
  const navigate = useNavigate();
  return (
    <div>
      <Header>Editing: {tenant.name || 'Undefined'}</Header>

      <Divider width="100%" />
      <PageBody>
        <Form onSubmit={handleSubmit} noValidate >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">

            <div className="col-sm-8">
              <p>
                <p>

                </p>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Name'
                    id='name'
                    value={tenant.name || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["name"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["name"]}
                  </Form.Control.Feedback>
                  <Form.Label>Description</Form.Label>
                  <Form.Control as="textarea" rows={3} 
                    className="form-control"
                    type="textarea"
                    placeholder='Description'
                    id='description'
                    value={tenant.description || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["description"]}

                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["description"]}
                  </Form.Control.Feedback>

                  <NodeSelect 
                    changeHandler={handleInputChange}
                    defaultValue={tenant.parent}
                    updateError={updateError}
                    elementId="parent" />
                  
                </Form.Group>
              </p>
              <p>
                <Form.Control.Feedback type="invalid">
                  {updateError["version"]}
                </Form.Control.Feedback>
              </p>

              <p>
                {updateError["non_field_errors"] && (
                  <div className="error">
                    {updateError["non_field_errors"]}
                  </div>
                )}
              </p>

              <div className="row">
                <div className="col mb-2" >
                  <div className="d-flex justify-content-between">
                    <div className="col start-0">
                      <Button variant="primary" type="submit" value="Update" >Update</Button>
                    </div>
                    <div className="col end-0">
                      <DeleteButton entityName={tenant.name} onDelete={handleDelete} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>


      </PageBody>
    </div>
  );
}

export default TenantEdit;
