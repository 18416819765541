import Form from "react-bootstrap/Form";
import React from "react";
import AsyncSelect from "react-select/async";

const ReferenceSelect = ({DeviceTypeName, loadOptions, onChange, updateError}) => {
    return (
        <>
            <Form.Label htmlFor={"RegisterFor" + DeviceTypeName}>{DeviceTypeName}</Form.Label>
            <AsyncSelect
                cacheOptions
                defaultOptions={true}
                loadOptions={loadOptions}
                isSearchable={true}
                placeholder="Type to search for EUI or name..."
                onChange={onChange}
                required={true}
            />
            <Form.Control.Feedback type="invalid">
                {updateError}
            </Form.Control.Feedback>
        </>
    );
};

export default ReferenceSelect;
