import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { handleApiError } from "../functions/apiErrorHandling";
import NodeSelect from "../Common/NodeSelect";
import ReactStateObject from "../../Extensions/ReactStateObject";
import NodeRepository from "../../Repositories/NodeRepository";

type Tenant = {
  name, description, parent
}

const TenantCreate = () => {
  const { state: tenant, onChange: handleInputChange } = ReactStateObject.useState<Tenant>();
  const [updateError, setUpdateError] = useState("");

  const handleSubmit = event => {
    event.preventDefault();
    console.log(tenant);
    NodeRepository.Create().createTenant(tenant)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );
  };

  // setup
  const navigate = useNavigate();
  return (
    <div>
      <Header>Create new tenant: {tenant.name || 'Undefined'}</Header>

      <Divider width="100%" />
      <PageBody>
        <Form onSubmit={handleSubmit} noValidate >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">

            <div className="col-sm-8">
              <p>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Name'
                    id='name'
                    value={tenant.name || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["name"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["name"]}
                  </Form.Control.Feedback>

                  <Form.Label>Description</Form.Label>                
                  <Form.Control as="textarea" rows={3} 
                    className="form-control"
                    type="textarea"
                    placeholder='Description'
                    id='description'
                    isInvalid={updateError["description"]}
                    value={tenant.description || ''}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["description"]}
                  </Form.Control.Feedback>

                  <NodeSelect 
                    changeHandler={handleInputChange}
                    defaultValue={tenant.parent?.id}
                    updateError={updateError}
                    elementId="parent" />

                </Form.Group>
              </p>
              <p>
                <Form.Control.Feedback type="invalid">
                  {updateError["version"]}
                </Form.Control.Feedback>
              </p>

              <p>
                {updateError["detail"] && (
                  <div className="error">
                    {updateError["detail"]}
                  </div>
                )}
              </p>

              <div className="row">
                <div className="col mb-2" >
                  <div className="d-flex justify-content-between">
                    <div className="col start-0">
                      <Button variant="primary" type="submit" value="Create" >Create</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>

      
      </PageBody>
    </div>
  );
}

export default TenantCreate;
