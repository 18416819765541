import React, { useState, useEffect } from "react";
import { useParams } from 'react-router-dom';
import CsvDownloader from 'react-csv-downloader';
import QRCodeRepository from "../../Repositories/QRCodeRepository";
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import QRCodesTable from "./CodesTable";
import { Button } from "react-bootstrap";

const QRDownloadBatch = () => {
    var { batch } = useParams();
    const [batchUrls, setBatchUrls] = useState([]);

    const GetBatchUrls = async (batch) => {
        const urls = await QRCodeRepository.Create().getBatchUrls(batch);
        const data = urls.map((u) => {
            return { url: u.url };
        });

        return data;
    };

    useEffect(() => {
        GetBatchUrls(batch).then(urls => setBatchUrls(urls));
    }, [batch]);

    const columns = [{
        "id": "url",
        "displayName": "URL"
    }];

    return (
        <>
            <Header>QR Code URLs download</Header>
            <Divider width="100%" color="red" />
            <PageBody>
                <QRCodesTable batch={batch} />

                <CsvDownloader
                    filename="qr-code-urls.csv"    
                    separator=";"
                    columns={columns}
                    datas={batchUrls}
                >
                    <Button className="btn btn-primary" variant="primary">Download as CSV ({batchUrls.length})</Button>
                </CsvDownloader>
            </PageBody>
        </>
    );
}

export default QRDownloadBatch;
