import React, { useContext, useMemo } from "react";
import { Link } from 'react-router-dom';
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";
import { Button } from "react-bootstrap";
import NetworkRepository from "../../Repositories/NetworkRepository";
import EntityListTableView from "../Common/EntityListTableView";
import { AppContext } from "../../App";

const Networks = () => {
    const { userProfile } = useContext(AppContext);
    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                size:50,
                Cell: ({ cell }) => (
                    <Link to={`/applications/${cell.getValue()}/edit`}>{cell.getValue()}</Link>
                ),
            },
            {
                accessorKey: 'name',
                header: 'Name',
            },
        ],
        [],
    );
    
    return (
        <div>
            <Header>Applications</Header>

            <Divider />
            <PageBody>
                <EntityListTableView 
                    entityNamePlural="Accounts"
                    entityFetch={(config) => NetworkRepository.Create().getNetworks(config)}
                    columns={columns}
                    getRowId={(row) => row.id}
                />

                <p hidden={!userProfile.is_staff}>
                    <Link to="/applications/create/"><Button variant="primary">Create New</Button></Link>
                </p>
            </PageBody>

        </div>
    );
}

export default Networks;
