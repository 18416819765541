import React from "react";
import Spinner from "./Spinner";
import { AsyncEffectState } from "../../Extensions/React/AsyncEffect/UseAsyncEffect";
import { AxiosError } from "axios";
import { Alert } from "react-bootstrap";

const AsyncLoadContainer = ({ state, children } : { state: AsyncEffectState, children }) => {
    const displayError = (error: AxiosError) => {
        return <Alert variant="danger" style={{margin: 20}}>{getErrorMessage(error)}</Alert>
    }

    const getErrorMessage = (error: AxiosError) => {
        switch (error.status) {
            case 404:
                return "Not found (404)";
            default:
                return "Unexpected error ("+ error.status +")";
        }
    }

    return (
        <>
            {
                state.isLoading
                ? <div style={{margin: 20}}>Loading... <Spinner /></div>
                : (state.error == null ? children : displayError(state.error))
            }
        </>
    );
}

export default AsyncLoadContainer;
