import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faBan, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const LastSeen = ({ value }) => {

  if (value === undefined) {
    return <FontAwesomeIcon style={{ color: 'darkred' }} icon={faBan} title="unknown" />
  }

  const title = new Date(value).toLocaleString();
  const now = new Date();
  const lastSeen = new Date(value);
  const SECONDS_IN_A_DAY = 60 * 60 * 24;
  if (((now.getTime() - lastSeen.getTime()) / 1000 - (SECONDS_IN_A_DAY)) > 0) {
      // last seen more than a day old
      return <FontAwesomeIcon style={{ color: 'darkred' }} icon={faCircleExclamation} title={title} />
  }
  
  // then we must be online (less than a day old)
  return <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} title={title} />
}

export default LastSeen;
