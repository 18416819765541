import React from "react";
import { useParams } from 'react-router-dom';
import Header from "../Header";
import SensorRegisterForm from "./SensorRegisterForm";
import GatewayRegisterForm from "./GatewayRegisterForm";
import QRScanRegisterForm from "./QRScanRegisterForm";

const QRCodeRegister = () => {
  var { qr } = useParams();

  return (
    <div>
      <Header>Link QR code to device</Header>

      <QRScanRegisterForm qr={qr} />
      <SensorRegisterForm qr={qr} />
      <GatewayRegisterForm qr={qr} />
    </div>
  );
}

export default QRCodeRegister;
