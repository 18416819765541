import { AxiosError } from "axios";
import AsyncEffectClosure from "./AsyncEffectClosure";

const asyncEffect = (
    closure: AsyncEffectClosure,
    setIsLoading: React.Dispatch<React.SetStateAction<boolean>>,
    setError: React.Dispatch<React.SetStateAction<AxiosError>>,
    cleanup?: () => void
) => {
    setIsLoading(true);
    setError(null);
    let abortController = new AbortController();
    const promise = closure(abortController.signal);
    if (promise === undefined) {
        setIsLoading(false); // legacy support
    } else {
        promise?.then(() => setIsLoading(false))
                .catch((error) => {
                    if (error.code == "ERR_CANCELED") {
                        setIsLoading(true);
                    } else {
                        console.log(error);
                        setError(error);
                        setIsLoading(false);
                    }
                });
    }
    
    return () => {
        setIsLoading(true);
        abortController.abort();
        if (typeof cleanup === "function") {
            cleanup();
        }
    }
}

export default asyncEffect;
