import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import { Modal } from "react-bootstrap";

const DeleteButton = ({ entityName, onDelete, disabled=false }) => {
  const [showDelete, setShowDelete] = useState(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);
  
  const deleteConfirmation = (event) => {
    event.preventDefault();
    onDelete(event);
    handleCloseDelete();
  };

  return (
    <>
      <Button variant="danger" value="Delete" onClick={handleShowDelete} disabled={disabled}>
        Delete
      </Button>

      <Modal show={showDelete} onHide={handleCloseDelete}>
        <Modal.Header closeButton>
          <Modal.Title>Delete {entityName}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Are you sure you want to delete {entityName}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDelete}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deleteConfirmation}>
            Yes
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default DeleteButton;
