import { AxiosInstance, AxiosRequestConfig, AxiosResponse } from "axios";
import ObjectFormDataMapper, { SkipProperty } from "../../Libs/HTTP/ObjectFormDataMapper";
import AxiosRequestConfigBuilder from "./AxiosRequestConfigBuilder";

class FormMappingAxios
{
    instance: AxiosInstance;
    mapper: ObjectFormDataMapper;

    constructor(instance: AxiosInstance) {
        this.mapper = new ObjectFormDataMapper();
        this.instance = instance;
    }

    getUri(config?: AxiosRequestConfig<any> | undefined): string {
        return this.instance.getUri(config);
    }

    request<T = any, R = AxiosResponse<T, any>, D = any>(config: AxiosRequestConfig<D>): Promise<R> {
        return this.instance.request(config);
    }

    get<T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: AxiosRequestConfig<D> | undefined): Promise<R> {
        return this.instance.get(url, config);
    }

    delete<T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: AxiosRequestConfig<D> | undefined): Promise<R> {
        return this.instance.delete(url, config);
    }

    head<T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: AxiosRequestConfig<D> | undefined): Promise<R> {
        return this.instance.head(url, config);
    }

    options<T = any, R = AxiosResponse<T, any>, D = any>(url: string, config?: AxiosRequestConfig<D> | undefined): Promise<R> {
        return this.instance.options(url, config);
    }

    post<T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D | undefined, config?: AxiosRequestConfig<D> | undefined): Promise<R> {
        return this.instance.post(url, data, config);
    }

    put<T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D | undefined, config?: AxiosRequestConfig<D> | undefined): Promise<R> {
        return this.instance.put(url, data, config);
    }

    patch<T = any, R = AxiosResponse<T, any>, D = any>(url: string, data?: D | undefined, config?: AxiosRequestConfig<D> | undefined): Promise<R> {
        return this.instance.patch(url, data, config);
    }

    postForm<D extends object, T = any, R = AxiosResponse<T, any>>(
        url: string,
        data?: D | undefined,
        config?: AxiosRequestConfig<D> | undefined,
        skips?: SkipProperty[]
    ): Promise<R> {
        const { mapped, mappedConfig } = this.map<D>(data, config, skips);
        return this.instance.postForm(url, mapped, mappedConfig);
    }

    putForm<D extends object, T = any, R = AxiosResponse<T, any>>(
        url: string,
        data?: D | undefined,
        config?: AxiosRequestConfig<D> | undefined,
        skips?: SkipProperty[]
    ): Promise<R> {
        const { mapped, mappedConfig } = this.map<D>(data, config, skips);
        return this.instance.putForm(url, mapped, mappedConfig);
    }

    patchForm<D extends object, T = any, R = AxiosResponse<T, any>>(
        url: string,
        data?: D | undefined,
        config?: AxiosRequestConfig<D> | undefined,
        skips?: SkipProperty[]
    ): Promise<R> {
        const { mapped, mappedConfig } = this.map<D>(data, config, skips);
        return this.instance.patchForm(url, mapped, mappedConfig);
    }

    private map<D extends object>(data?: D | undefined, config?: AxiosRequestConfig<D> | undefined, skips?: SkipProperty[]) {
        const mapped = this.mapper.map(data, skips);
        const mappedConfigData = this.mapper.map(config?.data, skips);
        const mappedConfig = AxiosRequestConfigBuilder.create<FormData>().withData(mappedConfigData).build();
        if (mapped != null) {
            console.table(Array.from(mapped.entries()));
        }

        return { mapped, mappedConfig };
    }
}

export default FormMappingAxios;
