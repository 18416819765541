export const handleApiError = (errorResponse, setUpdateError, setErrorMsg?: ({}) => void) => {
  if (!(errorResponse && errorResponse.response && errorResponse.response.data)) {
    setUpdateError("An unexpected error occurred. (1)");
    return;
  }

  if (!errorResponse.response.data) {
    setUpdateError("An unexpected error occurred. (2)");
    return;
  }

  const validationErrors = errorResponse.response.data;
  setUpdateError(validationErrors);

  if (setErrorMsg !== undefined && setErrorMsg != null) {
    Object.keys(validationErrors).map((key) => {
      Object.keys(validationErrors[key]).map((key2) => {
        updateField(setErrorMsg, key, validationErrors[key][key2]);
        return true;
      });
      return true;
    });
  }
};

function updateField(setErrorMsg, key, value) {
  setErrorMsg((prevErrorMsg) => ({
    ...prevErrorMsg,
    [key]: value,
  }));
}
