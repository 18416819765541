import React, { useState } from "react";
import Connected from "../Common/Connected";
import useAsyncEffect from "../../Extensions/React/AsyncEffect/UseAsyncEffect";
import GatewayRepository from "../../Repositories/GatewayRepository";
import Spinner from "../Common/Spinner";

const ShowLastSeen = ({ eui }) => {
  const gatewayRepository = new GatewayRepository();
  const [gateway, setGateway] = useState<{eui, name, description, third_party_data}>();
  const [thirdPartyJson, setThirdPartyJson] = useState<{connected, lastPong}>();

  const asyncEffectState = useAsyncEffect({ fetchAsync: (abortSignal) => {
    async function getGateway(eui) {
      try {
        const response = await gatewayRepository.getGateway(eui, abortSignal);

        setGateway(response.data);
        if (gateway?.third_party_data != null) {
          try {
            setThirdPartyJson(JSON.parse(gateway.third_party_data))
          } catch (error) {
            setThirdPartyJson({connected: null, lastPong: null});
          }
        }
      } catch (error) {
        if (error.code == "ERR_CANCELED") {
            //return;
            throw error;
        }
        console.log("getGateway Error: " + JSON.stringify(error));
        setGateway({ "eui": null, "name": "N/A", "description": "N/A", "third_party_data": "" });
      }
    }
    
    console.log("isLoading: " + asyncEffectState.isLoading);
    if ((eui === undefined) || (eui.length !== 16)) {
      console.log("gatewayID" + JSON.stringify(eui))
    } else {
      return getGateway(eui);
    }
  }, deps: [eui, gateway?.eui]});

  // setup
  return (
    <>
      { asyncEffectState.isLoading ? <Spinner /> : <Connected connectedStatus={thirdPartyJson?.connected} dateValue={thirdPartyJson?.lastPong} /> }
    </>
  )
}

export default ShowLastSeen;
