import React, { useContext, useState } from "react";
import DItem from "../DItem";
import useAsyncEffect from "../../Extensions/React/AsyncEffect/UseAsyncEffect";
import AxiosRequestConfigBuilder from "../../Extensions/Axios/AxiosRequestConfigBuilder";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { AppContext } from "../../App";

function Tile (
{
  entityName,
  link,
  fetchEntities
} : {
  entityName: string, 
  link: string,
  fetchEntities: (config: AxiosRequestConfig) => Promise<AxiosResponse>,
}) {
  const { globalViewNode } = useContext(AppContext);
  const [entities, setEntities] = useState([]);
  const configBuilder = AxiosRequestConfigBuilder.create()
    .withParams({
        'offset': 0,
        'limit': 1,
    });

  useAsyncEffect({ fetchAsync: (abortSignal) => {
    async function getEntities() {
      await fetchEntities(
          configBuilder
            .withAbortSignal(abortSignal)
            .withGlobalViewNode(globalViewNode)
            .build()
        )
        .then(
          (response) => {
            console.log("Response data" + JSON.stringify(response.data))
            setEntities(response.data.count);
          },
          (error) => {
            const _event =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            console.log("getEntities Error:" + JSON.stringify(_event))
            setEntities([]);
          }
        )
        .catch((error) => {
            console.log('There has been a problem with your getsites operation: ' + error.message);
        });
    }

    return getEntities();
  }, deps: [globalViewNode]});

  return <DItem name={entityName} value={entities} link={link} />;
};

export default Tile;
