import { AxiosRequestConfig } from 'axios';
import api from '../Service/api';

const SENSOR = 2;
const GATEWAY = 3;

class QRCodeRepository {
    static Create(): QRCodeRepository {
        return new QRCodeRepository();
    }

    getQRCodeTargetUrl(qr) {
        var result = api.get("qr/" + qr + '/url');
        return (result);
    }
    
    getQRCodes(config: AxiosRequestConfig) {
        return api.get("qr/", config);
    }
    
    async getBatchUrls(batch, offset = 0, limit = 10000) {
        const searchParams = {
            params: {
                offset: offset,
                limit: limit,
                batch: batch
            }
        };
        var result = await api.get("qr/", searchParams);
        return result.data.results.map(qr => ({ url: 'https://qr.sensesolutions.dk/' + qr.id }));
    }
    
    async register(qr, referenceType, referenceId) {
        var result = await api.patch("qr/" + qr + "/", {
            "referenceType": referenceType,
            "referenceId": referenceId
        })
        return (result);
    }

    async createBatch(node: number, amount: number) {
        var result = await api.post("qr-batch/", {
            nodeId: node,
            amount: amount
        });

        return result;
    }

    sensorCreated(qr, eui) {
        return this.register(qr, SENSOR, eui)
    }
    gatewayCreated(qr, eui) {
        return this.register(qr, GATEWAY, eui)
    }
};
 
export default QRCodeRepository;
