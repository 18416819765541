import React, { useState, useMemo } from "react";
import { Link } from 'react-router-dom';
import RecordService from '../../Service/record.service'
import Button from 'react-bootstrap/Button'
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";
import { useMediaQuery } from 'react-responsive'
import SensorTypeRepository from "../../Repositories/SensorTypeRepository";
import EntityListTableView from "../Common/EntityListTableView";
import useAsyncEffect from "../../Extensions/React/AsyncEffect/UseAsyncEffect";
import AxiosRequestConfigBuilder from "../../Extensions/Axios/AxiosRequestConfigBuilder";

const SensorTypes = () => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const [vendors, setVendors] = useState({});

    useAsyncEffect({ fetchAsync: (abortSignal) => {
        const config = AxiosRequestConfigBuilder.create()
            .withAbortSignal(abortSignal)
            .build();

        return RecordService.getVendors(config)
            .then((response) => {
                const results = response.data.results;
                const vendorsData = results.reduce(
                    (acc, value) => ({ ...acc, [value.url]: value.name }),
                    {});
                setVendors(vendorsData);
            })
            .catch((error) => {
                if (error.code == "ERR_CANCELED") {
                    return;
                }
                console.error(error);
            });
    }, deps: []});

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'Id',
                size: 10,
            },
            {
                accessorKey: 'name',
                header: 'Name',
                size: 100,
            },
            {
                id: 'vendor__name',
                accessorKey: 'vendor',
                header: 'Vendor',
                size: 50,
                Cell: ({ cell }) => (
                    <div>{vendors[cell.getValue()]}</div>
                ),
                enableSorting: false,
            },
            {
                accessorKey: 'version',
                header: 'Version',
                size: 50,
            },
            {
                enableColumnActions: false,
                enableColumnFilter: false,
                enableSorting: false,
                accessorKey: 'id',
                header: 'Edit',
                size: 20,
                Cell: ({ row,cell }) => (
                    <Link to={`/sensortypes/${cell.getValue()}/edit`}>
                        <Button size="sm" variant={(row.original.user_has_permission ? 'outline-success' : 'secondary')}>{(row.original.user_has_permission ? 'edit' : 'view')}</Button>
                    </Link>
                ),
            }
        ],
        [vendors],
    );
    
    return (
        <div>
            <Header>Sensor Types</Header>

            <Divider />
            <PageBody>
                <EntityListTableView
                    entityNamePlural="SensorTypes"
                    entityFetch={(config) => SensorTypeRepository.Create().getSensorTypes(config)}
                    columns={columns}
                    columnVisibility={{
                        "vendor": isDesktopOrLaptop,
                        "version": isDesktopOrLaptop,
                        "decoder": isDesktopOrLaptop,
                    }}
                    getRowId={(row) => row.id}
                />
                <p>
                    <Link to="/sensortypes/create/"><Button variant="primary">Create New</Button></Link>
                </p>
            </PageBody>

        </div>
    );
}

export default SensorTypes;
