import { AxiosRequestConfig } from 'axios';
import api from '../Service/api';
import AxiosRequestConfigBuilder from '../Extensions/Axios/AxiosRequestConfigBuilder';

class UserRepository {
    static Create(): UserRepository {
        return new UserRepository();
    }

    getUsers(config: AxiosRequestConfig) {
      return api.get("users/", config);
    }

    getUser(userId) {
      return api.get("users/" + userId + "/");
    }

    updateUser(user) {
      var result = api.put(user.url, user);
      return (result);
    }
    
    createUser(user) {
      var result = api.post("users/", user);
      return (result);
    }

    deleteUser(user) {
      var result = api.delete(user.url);
      return (result);
    }

    getUserProfile(abortSignal?: AbortSignal) {
      const config = abortSignal ? AxiosRequestConfigBuilder.create().withAbortSignal(abortSignal).build() : undefined;
      return api.get("userprofile/", config);
    }
    
    updateUserProfile(userProfile) {
      return api.putForm("update_userprofile/", userProfile);
    }
}

export default UserRepository;
