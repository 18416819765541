import React from "react";
import { Routes, Route } from "react-router-dom";
import SensorTypes from './SensorTypes';
import SensorTypeEdit from './Edit';
import SensorTypeCreate from './Create';
import Redirect from "../../Extensions/React/ReactRouter/Redirect";

const SensorTypeRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<SensorTypes/>} />
            <Route path="/create/" element={<SensorTypeCreate/>} />
            <Route path="/:sensorTypeId/*">
                <Route path="edit" element={<SensorTypeEdit/>} />
            </Route>

            { /* deprecated */ }
            <Route path="/edit/:sensorTypeId" element={<Redirect to="/sensortypes/:sensorTypeId/edit"/>} />
        </Routes>
    );
}

export default SensorTypeRoutes;
