import React from "react";
import AsyncLoadContainer from "./AsyncLoadContainer";
import PageBody from "../PageBody";
import { AsyncEffectState } from "../../Extensions/React/AsyncEffect/UseAsyncEffect";

const AsyncPageBody = ({ state, children } : { state: AsyncEffectState, children }) => {
    return (
        <AsyncLoadContainer state={state}>
            <PageBody>
                { children }
            </PageBody>
        </AsyncLoadContainer>
    );
}

export default AsyncPageBody;
