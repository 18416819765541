import Form from 'react-bootstrap/Form';
import React, { useState } from "react";
import useAsyncEffect from '../../Extensions/React/AsyncEffect/UseAsyncEffect';
import MobileNetworkRepository from '../../Repositories/MobileNetworkRegistry';
import AxiosRequestConfigBuilder from '../../Extensions/Axios/AxiosRequestConfigBuilder';

const MobileNetworkSelect = ({ changeHandler, defaultValue, updateError, disabled = false }) => {
  const [mobileNetworks, setMobileNetworks] = useState<{id, name, url}[]>([]);
  const urlParams = {
    params:
    {
      'offset': 0,
      'limit': 1000,
    }
  }

  useAsyncEffect({ fetchAsync: (abortSignal) => {
    const config = AxiosRequestConfigBuilder.create().withParams(urlParams).withAbortSignal(abortSignal).build();

    async function getMobileNetworks() {
      MobileNetworkRepository.Create().getMobileNetworks(config)
        .then(
          (response) => {
            console.log(response.data.results)
            setMobileNetworks(response.data.results);
          },
          (error) => {
            const _event =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            console.log("getMobileNetworks Error:" + JSON.stringify(_event))
            setMobileNetworks([{ id: "N/A", name: "N/A", url: null }]);
          }
        )
        .catch((error) => {
            console.log('There has been a problem with your getsites operation: ' + error.message);
          }
        );
    }

    return getMobileNetworks();
  }, deps: []});

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    console.log(id, value)
    changeHandler(event)
  }

  return (
    <>
      <Form.Group>
      <Form.Label htmlFor="MobileNetworks">Mobile Network</Form.Label>

      <Form.Select
        required
        id="MobileNetwork" 
        aria-label="Default select example" 
        onChange={handleInputChange} 
        value={defaultValue}
        isInvalid={updateError["MobileNetwork"]}
        disabled={disabled}
      >
        <option value="">Select</option>
        {mobileNetworks.map((MobileNetwork) => {
          return (
            <option key={MobileNetwork.id} value={MobileNetwork.url}>
              {MobileNetwork.name}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {updateError["MobileNetwork"]}
      </Form.Control.Feedback>
    </Form.Group>
    </>
  );
};

export default MobileNetworkSelect;
