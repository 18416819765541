import React, { useMemo } from "react";
import { Link } from 'react-router-dom';
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import NodeRepository from "../../Repositories/NodeRepository";
import EntityListTableView from "../Common/EntityListTableView";

const Nodes = () => {
    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                size: 20,
                Cell: ({ row, cell }) => (
                    <>
                        {row.original.user_has_permission ? (
                            <Link to={`/tenants/${cell.getValue()}/edit`}>
                                <FontAwesomeIcon style={{ color: 'green' }} icon={faCircleCheck} title="Edit" />
                            </Link>
                        ) : (
                            <FontAwesomeIcon style={{ color: 'blue' }} icon={faCircleInfo} title="Info" />
                        )}
                    </>
                ),
            },
            {
                accessorKey: 'name',
                header: 'name',
            },
            {
                id: 'path',
                accessorKey: '__self__',
                header: 'Path',
                enableSorting: false,
            },
        ],
        [],
    );
    
    return (
        <div>
            <Header>Tenants</Header>

            <Divider />
            <PageBody>

                <EntityListTableView
                    entityNamePlural="Tenants"
                    entityFetch={(config) => NodeRepository.Create().getNodesParents(config)}
                    columns={columns}
                    getRowId={(row) => row.id}
                />
                <br />
                <p>
                    <Link to="/tenants/create/"><Button variant="primary">Create New</Button></Link>
                </p>
            </PageBody>

        </div>
    );
}

export default Nodes;
