import { AxiosRequestConfig } from 'axios';
import api from '../Service/api';

class SimcardRepository {
    static Create(): SimcardRepository {
        return new SimcardRepository();
    }

    getSimcard = (iccid) => {
        return api.get("simcards/" + iccid)
    }

    getSimcardsList = (config: AxiosRequestConfig) => {
        return api.get("simcards/", config);
    }
    
    createSimcard = (simcard) => {
        return api.postForm("/simcards/", simcard);
    }

    updateSimcard = (simcard) => {
        return api.putForm(simcard.url, simcard);  
    }      
    
    deleteSimcard(iccid) {
        return api.delete("simcards/" + iccid)
    }
}

export default SimcardRepository;
