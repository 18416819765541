import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import { handleApiError } from "../functions/apiErrorHandling";
import NodeSelect from "../Common/NodeSelect";
import ReactStateObject from "../../Extensions/ReactStateObject";
import NetworkRepository from "../../Repositories/NetworkRepository";

const ApplicationCreate = () => {
  const { state: application, onChange: handleInputChange } = ReactStateObject.useState<{name, apiurl, token, application_token, node}>();
  const [updateError, setUpdateError] = useState("");

  const handleSubmit = event => {
    event.preventDefault();
    console.log(application);
    NetworkRepository.Create().createNetwork(application)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );
  };

  // setup
  const navigate = useNavigate();
  return (
    <div>
      <Header>Create new application: {application.name || 'Undefined'}</Header>

      <Divider width="100%" />
      <PageBody>
        <Form onSubmit={handleSubmit} noValidate >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">

            <div className="col-sm-8">
              <p>
                <Form.Group>

                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Name'
                    id='name'
                    value={application.name || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["name"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["name"]}
                  </Form.Control.Feedback>


                  <Form.Label>API Url</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='https://my.api.url'
                    id='apiurl'
                    value={application.apiurl || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["apiurl"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["apiurl"]}
                  </Form.Control.Feedback>


                  <Form.Label>Token</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Token'
                    id='token'
                    value={application.token || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["token"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["token"]}
                  </Form.Control.Feedback>


                  <Form.Label>application_token</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Application token'
                    id='application_token'
                    value={application.application_token || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["application_token"]}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["application_token"]}
                  </Form.Control.Feedback>

                  <NodeSelect
                    changeHandler={handleInputChange}
                    defaultValue={application.node}
                    updateError={updateError}
                  />
                </Form.Group>
              </p>
              <p>
                <Form.Control.Feedback type="invalid">
                  {updateError["version"]}
                </Form.Control.Feedback>
              </p>


              <p>
                {updateError["detail"] && (
                  <div className="error">
                    {updateError["detail"]}
                  </div>
                )}
              </p>

              <div className="row">
                <div className="col mb-2" >
                  <div className="d-flex justify-content-between">
                    <div className="col start-0">
                      <Button variant="primary" type="submit" value="Create" >Create</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>


      </PageBody>
    </div>
  );
}

export default ApplicationCreate;
