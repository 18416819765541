import React, { useMemo } from "react";
import { Link } from 'react-router-dom';
import SimcardRepository from '../../Repositories/SimcardRepository'
import Button from 'react-bootstrap/Button'
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";
import { useMediaQuery } from 'react-responsive'
import {Row,Col} from 'react-bootstrap'
import EntityListTableView from "../Common/EntityListTableView";
import { MRT_ColumnDef } from "material-react-table";

const Simcards = ({ ...props }) => {

    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });
    const columns = useMemo(
        (): MRT_ColumnDef<object, any>[] => [
            {
                accessorKey: 'active',
                header: 'Active',
                size: 110,
                Cell: ({ cell }) => (
                    <div>{(cell.getValue() ? "Yes" : "No")}</div>
                ),
                filterFn: 'equals',
                filterSelectOptions: ['True', 'False'],
                filterVariant: 'select',
            },
            {
                accessorKey: 'iccid',
                header: 'ICC',
                Cell: ({ cell }) => (
                    <Link to={`/simcards/${cell.getValue()}/edit`}>{cell.getValue()}</Link>
                ),
            },
            {
                accessorKey: 'note',
                header: 'Note',
                enableSorting: false,
            },
            {
                id: 'MobileNetwork__name',
                accessorKey: 'MobileNetwork',
                header: 'Mobile Network',
                enableSorting: false,
                Cell: ({ cell }) =>
                    cell.getValue() 
            },
        ],
        []
    );
    
    return (
        <div>
            <Header>Simcards</Header>

            <Divider />
            <PageBody>

            <EntityListTableView
                    entityNamePlural="Simcards"
                    entityFetch={(config) => SimcardRepository.Create().getSimcardsList(config)}
                    columns={columns}
                    columnVisibility={{
                        "note": isDesktopOrLaptop,
                    }}
                    getRowId={(row) => row.iccid}
                />
                <br />
                    <Row>
                         <Col className=" mb-2" >
                            <div className="d-flex justify-content-between">
                                <Col className="start-0">
                                <Link to="/Simcards/create/"><Button variant="primary">Create New</Button></Link>
                                </Col>
                            </div>
                        </Col>
                    </Row>
            </PageBody>
        </div>
    );
}

export default Simcards;
