import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCheckCircle, faBan, faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

const Connected = ({ connectedStatus, dateValue }) => {
    
    const title = new Date(dateValue).toLocaleString();
    if (!connectedStatus) {
        // last seen more than a day old
        return <FontAwesomeIcon style={{ color: 'darkred' }} icon={faCircleExclamation} title={title} />
    } else {
        // then we must be online (less than a day old)
        return <FontAwesomeIcon style={{ color: 'green' }} icon={faCheckCircle} title={title} />
    }
}

export default Connected;
