import React from 'react';
import { Link } from 'react-router-dom';

function DItem({ name, value,link,content=""}) {
    return (
        <div className="DItem"><Link to={link}>{name} <span className="DItemValue">{value}</span></Link></div>
    )
}

export default DItem;
