import React from 'react';
import { DebounceInput } from 'react-debounce-input';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';

const Location = ({device, handleInputChange}) => {
  return (
      <Row>
        <Col>
          <Form.Label form="location" className="col-sm-3 col-form-label col-form-label-sm">Coordinates:</Form.Label>
        </Col>
        <Col>
          <DebounceInput
            className="form-control form-control-sm"
            label='location'
            type="text"
            placeholder='fx. 55.6928608,12.5992622'
            id='location'
            value={device.location}
            onChange={handleInputChange}
            required
          />
        </Col>
      </Row>
  )
}

export default Location;
