const getToken = () => {
    const token = localStorage.getItem("token");
    if (typeof token !== "undefined" && token != null) {
        return JSON.parse(token);
    } else {
        return undefined;
    }
};

const setToken = (token) => {
    localStorage.setItem("token", JSON.stringify(token));
};

const removeToken = () => {
    localStorage.removeItem("token");
};

const TokenService = {
    getToken,
    setToken,
    removeToken,
};

export default TokenService;
