import React from "react";
import { Routes, Route } from "react-router-dom";
import Applications from './Applications';
import ApplicationEdit from './Edit';
import ApplicationCreate from './Create';
import Redirect from "../../Extensions/React/ReactRouter/Redirect";

const ApplicationRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Applications/>} />
            <Route path="/create/" element={<ApplicationCreate/>} />
            <Route path="/:applicationId/*">
                <Route path="edit" element={<ApplicationEdit/>} />
            </Route>

            { /* deprecated */ }
            <Route path="/edit/:applicationId" element={<Redirect to={"/applications/:applicationId/edit"} />} />
        </Routes>
    );
}

export default ApplicationRoutes;
