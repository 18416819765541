import React from "react";
import { Routes, Route } from "react-router-dom";
import Accounts from './Accounts';

const AccountRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Accounts/>} />
        </Routes>
    );
}

export default AccountRoutes;
