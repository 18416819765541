import React, { useState } from "react";
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import SimcardRepository from '../../Repositories/SimcardRepository';
import MobileNetworkSelect from "./MobileNetworkSelect";
import NodeSelect from "../Common/NodeSelect";
import { handleApiError } from "../functions/apiErrorHandling";

type Simcard = {
  iccid, pincode, pukcode, note, active, mobilenetwork
}

const defaultSimcard: Simcard = {iccid: null, pincode: null, pukcode: null, note: null, active: null, mobilenetwork: null};

const Simcard = () => {
  const [simcard, setSimcard] = useState<Simcard>(defaultSimcard);
  const [updateError, setUpdateError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    switch (id) {
      case 'active':
        setSimcard((prevSimcard) => ({
          ...prevSimcard,
          [id]: !(value === 'Active'),
        }));
        break;
      default:
        setSimcard((prevSimcard) => ({
          ...prevSimcard,
          [id]: value,
        }));
    }
  };


  const handleSubmit = event => {
    event.preventDefault();

    SimcardRepository.Create().createSimcard(simcard)
      .then(response => {
        console.log('Item created:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError, setFieldErrors);
        }
      );
  };

  const navigate = useNavigate();

  return (
    <div>
      <Header>New Simcard {simcard.iccid || ''}</Header>

      <Divider width="100%" color="red" />
      <PageBody>
        <Form noValidate onSubmit={handleSubmit} >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">

          <Form.Group>
                  <Form.Label>Iccid
                  </Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder='Iccid'
                    id='iccid'
                    value={simcard.iccid || ''}
                    isInvalid={fieldErrors["iccid"]}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors["iccid"]}
                  </Form.Control.Feedback>
                </Form.Group>

                  <MobileNetworkSelect changeHandler={handleInputChange} defaultValue={simcard.mobilenetwork} updateError={fieldErrors}></MobileNetworkSelect>
                  <NodeSelect changeHandler={handleInputChange} defaultValue={null} updateError={fieldErrors}></NodeSelect>

                  <Form.Group>
                  <Form.Label>Pincode
                  </Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder='Pincode'
                    id='pincode'
                    value={simcard.pincode || ''}
                    isInvalid={fieldErrors["pincode"]}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors["version"]}
                  </Form.Control.Feedback>
                </Form.Group>

                  <Form.Group>
                  <Form.Label>Pukcode
                  </Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder='Pukcode'
                    id='pukcode'
                    value={simcard.pukcode || ''}
                    isInvalid={fieldErrors["pukcode"]}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors["version"]}
                  </Form.Control.Feedback>
                </Form.Group>

                  <Form.Group>
                  <Form.Label>Note</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder='Note'
                    id='note'
                    value={simcard.note || ''}
                    isInvalid={fieldErrors["note"]}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors["version"]}
                  </Form.Control.Feedback>
                </Form.Group>
            <div className="col-sm-8">
              <p>
                <Button
                  className="form-control"
                  id="active"
                  variant={(simcard.active ? "success" : "danger")}
                  onClick={handleInputChange}
                  value={simcard.active ? "Active" : "Not Active"}
                >{simcard.active ? "Active" : "Not Active"}</Button>
              </p>


              </div>
          <div className="row">
            <div className="col mb-2" >
              <div className="d-flex justify-content-between">
                <div className="col start-0">
                  <Button variant="primary" type="submit" value="Create">Create</Button>
                </div>
              </div>
            </div>
          </div>
        </div>

        </Form>
      </PageBody>
    </div>
  );
}

export default Simcard;
