import { AxiosRequestConfig } from 'axios';
import api from '../Service/api';

class MobileNetworkRepository {
    static Create(): MobileNetworkRepository {
        return new MobileNetworkRepository();
    }

    getMobileNetworks = (config: AxiosRequestConfig) => {
        return api.get("mobilenetworks/", config);
    }
}

export default MobileNetworkRepository;
