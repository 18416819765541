import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './Login.css';
const { REACT_APP_SERVERURL } = process.env;
async function loginUser(credentials) {

  return fetch(REACT_APP_SERVERURL + 'api-token-auth/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json'
    },
    body: JSON.stringify(credentials)
  })
    .then(data => data.json())
    .catch(
      (error) => {
        console.log('There has been a problem with your login operation: ' + error.message);
        return (error);
      }
    )
}

export default function Login({ setToken }) {
  const [username, setUserName] = useState("");
  const [password, setPassword] = useState("");
  const [errorMsg, setErrormsg] = useState("");
  const handleSubmit = async e => {
    e.preventDefault();
    const token = await loginUser({
      username,
      password
    });

    if (token?.non_field_errors) {
      setErrormsg(token?.non_field_errors[0]);
    } else {
      if (token?.message) {
        setErrormsg(token?.message);
      } else {
        setToken(token);
      }
    }
  }

  return (
    <div className="login-wrapper">
      <h1>Please Log In</h1>
      <form onSubmit={handleSubmit}>
        <div className="mb-3">
          <label className="form-label" htmlFor="username">Username</label>
          <input id="username" className="form-control" type="text" onChange={e => setUserName(e.target.value)} />

        </div>
        <div className="mb-3">
          <label className="form-label" htmlFor="password">Password     </label>
          <input id="password" className="form-control" type="password" onChange={e => setPassword(e.target.value)} />
        </div>
        <div>
          <button className="btn-primary btn" type="submit">Submit</button>
        </div>
        <div>{errorMsg}</div>
      </form>
    </div>
  )
}

Login.propTypes = {
  setToken: PropTypes.func.isRequired
};
