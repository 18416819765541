import { AxiosRequestConfig } from "axios";
import { ColumnFiltersState, SortingState } from "@tanstack/table-core";
import Pagination from "../../Libs/HTTP/Pagination";
import PaginatedListState from "../React/Pagination/PaginatedListState";

class AxiosRequestConfigBuilder<D = any>
{
    config: AxiosRequestConfig = {}

    static create<D = any>(config?: AxiosRequestConfig<D>): AxiosRequestConfigBuilder {
        return new AxiosRequestConfigBuilder(config);
    }

    constructor(config?: AxiosRequestConfig<D>) {
        this.config = config ?? {};
    }

    withData(data: D) {
        this.config.data = data;
        return this;
    }

    withParam(name: string, value: any) {
        this.config.params ??= {}
        this.config.params[name] = value;
        return this;
    }

    withParams(params?: object) {
        this.config.params = params ?? {};
        return this;
    }

    withParamsAdded(params?: object) {
        if (params != null) {
            this.config.params = {...this.config.params, ...params};
        }
        
        return this;
    }

    withGlobalViewNode(node?: { id: number }) {
        if (node != null) {
            return this.withParam('view_node', node.id)
        }

        return this;
    }

    withPagination(pagination: Pagination) {
        return this.withPaginationLimitAndOffset(pagination.pageSize, pagination.getOffset());
    }

    withPaginationLimitAndOffset(limit: number, offset: number = 0) {
        return this.withParamsAdded({
            limit: limit,
            offset: offset
        });
    }

    withFilters(filters?: ColumnFiltersState) {
        if (filters == null) {
            return this;
        }

        for (let f of filters) {
            this.withParam(f.id, f.value);
        }
        
        return this;
    }

    withSearch(search?: string) {
        return this.withParam("search", search);
    }

    withOrdering(ordering?: SortingState) {
        /**
         * desc: boolean;
         * id: string;
         */
        let orderArray: string[] = [];
        if (ordering != null) {
            orderArray = ordering.map(o => (o.desc ? "-" : "") + o.id);
        }

        return this.withParam("ordering", orderArray.join(","));
    }

    withPaginatedListState(state: PaginatedListState) {
        return this
            .withPagination(new Pagination(state.pagination.pageSize, state.pagination.pageIndex))
            .withFilters(state.columnFilters)
            .withSearch(state.searchFilter)
            .withOrdering(state.sorting);
    }

    withAbortSignal(abortSignal?: AbortSignal) {
        this.config.signal = abortSignal;
        return this;
    }

    build(): AxiosRequestConfig {
        return this.config;
    }
}

export default AxiosRequestConfigBuilder;
