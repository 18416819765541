import React, { useMemo } from "react";
import { Link } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import QRCodeRepository from "../../Repositories/QRCodeRepository";
import EntityListTableView from "../Common/EntityListTableView";
import AxiosRequestConfigBuilder from "../../Extensions/Axios/AxiosRequestConfigBuilder";

const QRCodesTable = ({ batch = null }: { batch?: string | number | null }) => {
    const isDesktopOrLaptop = useMediaQuery({ query: "(min-width: 1224px)" });
    const referenceTypeMap = {
        2: "Sensor",
        3: "Gateway",
    };

    const columns = useMemo(
        () => [
            {
                accessorKey: "id",
                header: "ID",
                Cell: ({ cell }) => (
                    <Link to={`/qr/${cell.getValue()}`}>{cell.getValue()}</Link>
                ),
            },
            {
                accessorKey: "batch",
                header: "Batch",
                Cell: ({ cell }) => (
                    <Link to={`/qr/codes/batch/${cell.getValue()}`}>
                        {cell.getValue()}
                    </Link>
                ),
            },
            {
                accessorKey: "referenceType",
                header: "Reference Type",
                Cell: ({ cell }) =>
                    referenceTypeMap[cell.getValue()] ?? cell.getValue(),
            },
            {
                accessorKey: "referenceId",
                header: "Reference Id",
            },
        ],
        []
    );

    return (
        <>
            <EntityListTableView
                entityNamePlural="QR Codes"
                entityFetch={(config) => {
                    if (batch != null) {
                        config = AxiosRequestConfigBuilder.create(config)
                            .withParam("batch", batch)
                            .build();
                    }

                    return QRCodeRepository.Create().getQRCodes(config);
                }}
                columns={columns}
                columnVisibility={{
                    name: isDesktopOrLaptop,
                    city: isDesktopOrLaptop,
                    description: isDesktopOrLaptop,
                }}
                getRowId={(row) => row.eui}
            />
        </>
    );
};

export default QRCodesTable;
