import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import { Row, Col } from "react-bootstrap"
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import NodeSelect from "../Common/NodeSelect";
import { handleApiError } from "../functions/apiErrorHandling";
import UserRepository from "../../Repositories/UserRepository";

const UserCreate = () => {
  const [user, setUser] = useState(
    {
      email: "",
      first_name: "",
      last_name: "",
      username: "",
      profile: {
        role: 2,
        node: null,
      }
    }
  );

  const [updateError, setUpdateError] = useState("");

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    console.log(id, value)
    switch (id) {
      case "node":

        setUser((prevUser) => ({
          ...prevUser,
          "profile" : {
            ...prevUser.profile,
            "node" : value
          }
        }
        ))
        break;
      default:
        setUser((prevUser) => ({
          ...prevUser,
          [id]: value,
        }));
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    console.log(user);
    UserRepository.Create().createUser(user)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );
  };

  const navigate = useNavigate();
  return (
    <div>
      <Header>Create new user: {user.username || ''}</Header>

      <Divider width="100%" />
      <PageBody>
        <Form onSubmit={handleSubmit} noValidate >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">

            <div className="col-sm-8">
              <Form.Group>
                <Row>
                  <Col>
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder='Username'
                      id='username'
                      value={user.username || ''}
                      onChange={handleInputChange}
                      required
                      isInvalid={updateError["username"]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["email"]}
                    </Form.Control.Feedback>
                  </Col>
                  <Col>
                    <Form.Label>Email</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder='Email'
                      id='email'
                      value={user.email || ''}
                      onChange={handleInputChange}
                      required
                      isInvalid={updateError["email"]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["email"]}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder='First Name'
                      id='first_name'
                      value={user.first_name || ''}
                      onChange={handleInputChange}
                      required
                      isInvalid={updateError["first_name"]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["first_name"]}
                    </Form.Control.Feedback>
                  </Col>
                  <Col>
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder='Last Name'
                      id='last_name'
                      value={user.last_name || ''}
                      onChange={handleInputChange}
                      required
                      isInvalid={updateError["last_name"]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["last_name"]}
                    </Form.Control.Feedback>
                  </Col>
                </Row>
                <NodeSelect
                  changeHandler={handleInputChange}
                  defaultValue={user.profile.node || ''}
                  updateError={{ "node": updateError["node"] }}
                ></NodeSelect>
              </Form.Group>


                <Form.Control.Feedback type="invalid">
                  {updateError["version"]}
                </Form.Control.Feedback>
                <br/>

                {updateError["non_field_errors"] && (
                  <div className="error">
                    {updateError["non_field_errors"]}
                  </div>
                )}



              <div className="row">
                <div className="col mb-2" >
                  <div className="d-flex justify-content-between">
                    <div className="col start-0">
                      <Button variant="primary" type="submit" value="Create" >Create</Button>
                    </div>

                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>


      </PageBody>
    </div>
  );
}

export default UserCreate;
