import React, { useEffect, useState } from "react";
import { useParams } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Editor from "@monaco-editor/react";
import VendorSelect from "./VendorSelect";
import NodeSelect from "../Common/NodeSelect";
import { handleApiError } from "../functions/apiErrorHandling";
import DeleteButton from "../Common/DeleteButton";
import SensorTypeRepository from "../../Repositories/SensorTypeRepository";

type Sensor = {
  id, name, vendor, model, version, decoder, node, user_has_permission
}

const SensorTypeEdit = () => {
  const [sensorType, setSensorType] = useState<Sensor>();
  var { sensorTypeId } = useParams();
  const [updateError, setUpdateError] = useState("");

  useEffect(() => {
    async function getSensorType(sensorTypeId) {
      try {
        const response = await SensorTypeRepository.Create().getSensorType(sensorTypeId);
        setSensorType(response.data);
        console.log(response.data)
      } catch (error) {
        console.log("getSensor Error: " + JSON.stringify(error));
        setSensorType({ "id": "N/A", "name": "N/A", "vendor": "N/A", model: null, "version": "N/A", "decoder": "N/A", "node": "N/A", user_has_permission: false });
      }
    }
    console.log("SensorTypeId=" + sensorTypeId)
    getSensorType(sensorTypeId);
  }, [sensorTypeId]);

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    console.log(id, value)
    switch (id) {
      default:
        setSensorType((prevSensorType) => ({
          ...prevSensorType,
          [id]: value,
        } as Sensor));
    }
  };

  const handleSubmit = event => {
    event.preventDefault();

    SensorTypeRepository.Create().updateSensorType(sensorType)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );
  };

  const handleEditorChange = (value, event) => {
    setSensorType((prevSensorType) => ({
      ...prevSensorType,
      ["decoder"]: value,
    } as Sensor));
  }

  const handleDelete = (event) => {
    SensorTypeRepository.Create().deleteSensorType(sensorType?.id)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );
  }

  // setup
  const navigate = useNavigate();

  return (
    <div>
      <Header>Editing: {sensorType?.name || 'Undefined'}</Header>

      <Divider width="100%" />
      <PageBody>
        <Form onSubmit={handleSubmit} noValidate >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">

            <div className="col-sm-8">
              <p>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Name'
                    id='name'
                    value={sensorType?.name || ''}
                    onChange={handleInputChange}
                    required
                    isInvalid={updateError["name"]}
                    disabled={!sensorType?.user_has_permission}
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["name"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </p>
              <p>
                <VendorSelect changeHandler={handleInputChange} defaultValue={sensorType?.vendor} updateError={updateError}  disabled={!sensorType?.user_has_permission}></VendorSelect>
              </p>
              <p>
                <NodeSelect changeHandler={handleInputChange} defaultValue={sensorType?.node} updateError={updateError}  disabled={!sensorType?.user_has_permission}></NodeSelect>
              </p>
              <p>
                <Form.Label>Model</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  placeholder='Model'
                  id='model'
                  value={sensorType?.model || ''}
                  onChange={handleInputChange}
                  isInvalid={updateError["model"]}
                  disabled={!sensorType?.user_has_permission}

                  required
                />
                <Form.Control.Feedback type="invalid">
                  {updateError["model"]}
                </Form.Control.Feedback>
              </p>
              <p>
                <Form.Label>Version</Form.Label>
                <Form.Control
                  className="form-control"
                  type="text"
                  placeholder='Version'
                  id='version'
                  value={sensorType?.version || ''}
                  onChange={handleInputChange}
                  disabled={!sensorType?.user_has_permission}

                  required
                />
                <Form.Control.Feedback type="invalid">
                  {updateError["version"]}
                </Form.Control.Feedback>
              </p>
              <p>
                <Form.Label>Decoder</Form.Label>
                <Editor
                  height="400px"
                  language="javascript"
                  theme="vs-light"
                  value={sensorType?.decoder || ''}
                  onChange={handleEditorChange}
                  options={{
                    readOnly: !sensorType?.user_has_permission,
                    inlineSuggest: {
                      enabled: true
                    },
                    fontSize: 16,
                    formatOnType: true,
                    autoClosingBrackets: "always",
                    minimap: { scale: 10 }
                  }}
                />

              </p>
              <p>
                {updateError["detail"] && (
                  <div className="error">
                    {updateError["detail"]}
                  </div>
                )}
              </p>

              <div className="row">
                <div className="col mb-2" >
                  <div className="d-flex justify-content-between">
                    <div className="col start-0">
                      <Button variant="primary" type="submit" value="Update" disabled={!sensorType?.user_has_permission}>Update</Button>
                    </div>
                    <div className="col end-0">
                      <DeleteButton entityName={sensorType?.name} onDelete={handleDelete} disabled={!sensorType?.user_has_permission} />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </PageBody>
    </div>
  );
}

export default SensorTypeEdit;
