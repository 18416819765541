import Form from 'react-bootstrap/Form';
import React, { useState } from "react";
import SensorTypeRepository from '../../Repositories/SensorTypeRepository';
import AxiosRequestConfigBuilder from '../../Extensions/Axios/AxiosRequestConfigBuilder';
import useAsyncEffect from '../../Extensions/React/AsyncEffect/UseAsyncEffect';

const SensorTypesSelect = ({changeHandler,defaultValue}) => {
  const [sensorTypes, setSensorTypes] = useState<{id, name, url}[]>([]);
  const configBuilder = AxiosRequestConfigBuilder.create()
    .withPaginationLimitAndOffset(1000);

  useAsyncEffect({ fetchAsync: (abortSignal) => {
    async function getSensorTypes() {
      console.log("getSensorType async")
      SensorTypeRepository.Create().getSensorTypes(configBuilder.withAbortSignal(abortSignal).build())
        .then(
          (response) => {
            setSensorTypes(response.data.results);
            console.log(sensorTypes)
          },
          (error) => {
            const _event =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            console.log("getSensorTypes Error:" + JSON.stringify(_event))
            setSensorTypes([{ id: "N/A", name: "N/A", url: null }])
          }
        )
        .catch(
          (error) => {
            console.log('There has been a problem with your getsites operation: ' + error.message);
          }
        )
        ;
    }

    return getSensorTypes();
  }, deps: [sensorTypes.length]});

  return (
    <>
      <Form.Label htmlFor="SensorType">Type</Form.Label>

      <Form.Select required id="type" aria-label="Default select example" onChange={changeHandler} value={defaultValue}>
      <option value="">Select</option>
      {sensorTypes.map((sensorType) => {
        return (
          <option key={sensorType.id} value={sensorType.url}>
            {sensorType.name} 
          </option>
        );
      })}
      </Form.Select>
    </>
  );
};

export default SensorTypesSelect;
