import React from "react";
import { generatePath, Navigate, useParams } from "react-router-dom";

const Redirect = ({to} : {to: string}) => {
    const params = useParams();
    return (
        <Navigate to={generatePath(to, params)} replace />
    );
}

export default Redirect;
