import React from "react";
import RegisterForm from "./RegisterForm";
import SensorRepository from "../../Repositories/SensorRepository";
import AxiosRequestConfigBuilder from "../../Extensions/Axios/AxiosRequestConfigBuilder"

const SensorRegisterForm = ({qr}) => {
    const GetOptions = async (inputValue) => {
        const config = AxiosRequestConfigBuilder.create()
            .withPaginationLimitAndOffset(100)
            .withSearch(inputValue)
            .build();

        try {
            const response = await SensorRepository.Create().getSensorsList(config);
            const sensors = response.data.results;
            const deviceOptions = sensors.map((e) => {
                return {
                    key: "sensor_" + e.eui,
                    type: "sensor",
                    typeId: 2,
                    value: e.eui,
                    name: e.name,
                    label: e.name + " (" + e.eui + ")"
                };
            });
            console.log(JSON.stringify(deviceOptions));
            return deviceOptions;
        } catch (error) {
            const _event =
                (error.response && error.response.data) ||
                error.message ||
                error.toString();
            console.log("getSensorList Error:" + JSON.stringify(_event));
        }
    };

    return <RegisterForm qr={qr} DeviceTypeName="Sensor" loadOptions={GetOptions} />
};

export default SensorRegisterForm;
