import { AxiosRequestConfig } from "axios";
import api from "../Service/api";

class SensorTypeRepository
{
    static Create(): SensorTypeRepository {
        return new SensorTypeRepository();
    }

    getSensorTypes(config: AxiosRequestConfig) {
        return api.get("sensortypes/", config);
    }

    getSensorType(id) {
      return api.get("sensortypes/" + id + "/");
    }

    createSensorType(sensorType) {
        return api.postForm('sensortypes/', sensorType);
    }

    updateSensorType(sensorType) {
      return api.putForm(sensorType.url, sensorType);
    }
    
    deleteSensorType(id) {
      return api.delete("sensortypes/" + id);
    }
}

export default SensorTypeRepository;
