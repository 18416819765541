import React from "react";
import { Routes, Route } from "react-router-dom";
import QRCodes from "./Codes"
import QRCreateBatch from "./CreateBatch"
import QRDownloadBatch from "./DownloadBatch"
import QRRegister from "./Register"
import QRRouter from "./Router"

const QRRoutes = () => {
    return (
        <Routes>
            <Route path="/:qr" element={<QRRouter />} />
            <Route path="/:qr/register" element={<QRRegister/>} />
            <Route path="/codes" element={<QRCodes/>} />
            <Route path="/codes/create" element={<QRCreateBatch/>} />
            <Route path="/codes/batch/:batch" element={<QRDownloadBatch/>} />
        </Routes>
    );
}

export default QRRoutes;
