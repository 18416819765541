import React, { useEffect, useState } from "react";
import Divider from "./Divider";
import Header from "./Header";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import PageBody from "./PageBody";
import { useNavigate } from 'react-router-dom';
import { handleApiError } from "./functions/apiErrorHandling";
import UserRepository from "../Repositories/UserRepository";

const UserProfile = () => {
    const [validated, setValidated] = useState(false);

    const [userProfile, setUserProfile] = useState(
        {
            email: "Loading .. ",
            first_name: "Loading ..",
            last_name: "Loading ..",
            last_login: "Loading .."
        }
    );
    const [updateError, setUpdateError] = useState("");
    const navigate = useNavigate();


    useEffect(() => {
        async function getUserProfile() {
            UserRepository.Create().getUserProfile()
                .then(
                    (response) => {
                        console.log(response.data)
                        setUserProfile(response.data);
                        console.log(response.data.count);
                    },
                    (error) => {
                        const _event =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                        console.log("getUserProfiles Error:" + JSON.stringify(_event))
                        setUserProfile({
                            email: "Loading .. ",
                            first_name: "Loading ..",
                            last_name: "Loading ..",
                            last_login: "Loading .."
                        })
                    }
                )
                .catch(
                    (error) => {
                        console.error('There has been a problem with your getsites operation: ' + error.message);
                    }
                );
        }

        getUserProfile();
    }, []);

    const handleInputChange = (e) => {
        e.preventDefault(); // prevent the default action
        const { id, value } = e.target;
        setUserProfile((prevUserProfile) => ({
            ...prevUserProfile,
            [id]: value,
        }));
    };

    const handleSubmit = event => {
        event.preventDefault();

        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }

        UserRepository.Create().updateUserProfile(userProfile)
            .then(response => {
                console.log('Item updated:', response.data);
                setValidated(true);
                navigate(-1);
            })
            .catch(error => {
                console.error(error);
                handleApiError(error, setUpdateError);
            });
    };


    return (
        <div>
            <Header> User Profile </Header>
            <Divider />
            <PageBody>
                <Form noValidate validated={validated}  onSubmit={handleSubmit}>
                    <Form.Group className="mb-3" controlId="email">
                        <Form.Label>Email address</Form.Label>
                        <Form.Control type="email" placeholder="Enter email" value={userProfile.email} onChange={handleInputChange}
                            required />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {updateError["email"]}
                        </Form.Control.Feedback>
                        <Form.Text className="">
                            {updateError["email"]}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="first_name">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text" placeholder="First Name" value={userProfile.first_name} onChange={handleInputChange}
                            required />
                        <Form.Control.Feedback>Looks good!</Form.Control.Feedback>
                        <Form.Control.Feedback type="invalid">
                            {updateError["email"]}
                        </Form.Control.Feedback>
                        <Form.Text className="">
                            {updateError["first_name"]}
                        </Form.Text>
                    </Form.Group>

                    <Form.Group className="mb-3" controlId="last_name">
                        <Form.Label>Last Name</Form.Label>

                        <Form.Control type="text" placeholder="Last name" value={userProfile.last_name} onChange={handleInputChange}
                            required />
                        <Form.Text className="">
                            {updateError["last_name"]}
                        </Form.Text>
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="last_login">
                <Form.Label>Last Login</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder='unknown'
                      id='last_name'
                      disabled={true}
                      value={(userProfile.last_login)?(new Date(userProfile.last_login)).toLocaleString(): ''}
                      onChange={handleInputChange}
                      required
                      isInvalid={updateError["last_login"]}
                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["last_login"]}
                    </Form.Control.Feedback>
                    </Form.Group>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>
                    <Form.Text className="">
                        {updateError && (
                            <div className="error">
                                <p>Error:</p>
                                <ul>
                                    {Object.keys(updateError).map((key, index) => (
                                        <li key={key}>{
                                            Object.keys(updateError[key]).map((key2, index) => (
                                                <div>{updateError[key][key2]},</div>
                                            ))

                                        }</li>
                                    ))}
                                </ul>
                            </div>
                        )}
                    </Form.Text>
                </Form>
            </PageBody>
        </div>

    );
};

export default UserProfile;
