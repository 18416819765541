import axios from "axios";
import TokenService from "./token.service";
import FormMappingAxios from "../Extensions/Axios/FormMappingAxiosInstance";

const { REACT_APP_SERVERURL } = process.env;

const instance = axios.create({
    baseURL: REACT_APP_SERVERURL,
    headers: {
        "Content-Type": "application/json",
    },
});

instance.interceptors.request.use(
    (config) => {
        const tokenObj = TokenService.getToken();
        if (tokenObj) {
            config.headers["Authorization"] = "Token " + tokenObj.token; // for Spring Boot back-end
        }
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

instance.interceptors.response.use(
    (res) => {
        return res;
    },
    async (err) => {
        if (err.code != "ERR_CANCELED") {
            console.log(err);
        }

        return Promise.reject(err);
    }
);

const api = new FormMappingAxios(instance);

export default api;
