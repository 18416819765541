import React, { useState } from "react";
import { DebounceInput } from 'react-debounce-input';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate, useSearchParams } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import QRCodeRepository from "../../Repositories/QRCodeRepository";
import SensorTypesSelect from "./SensorTypesSelect";
import NetworksSelect from "./NetworkSelect";
import NodeSelect from "../Common/NodeSelect";
import { handleApiError } from "../functions/apiErrorHandling";
import LocationMap from "../Common/LocationMap";
import { toast } from 'react-toastify';
import SensorRepository from "../../Repositories/SensorRepository";

type Sensor = {
  eui, join_eui, application_key, type, active, name, description, city, location, 
}

const defaultSensor = {
  eui: null,
  join_eui: null,
  application_key: null,
  type: null,
  active: false,
  name: null,
  description: null,
  city: null,
  location: null 
}

const Sensor = () => {
  const [searchParams] = useSearchParams();
  const [sensor, setSensor] = useState<Sensor>({...defaultSensor, ["eui"]: searchParams?.get("eui")});
  const [location, setLocation] = useState(null);
  const [file, setFile] = useState("");
  const [updateError, setUpdateError] = useState([]);
  const provider = new OpenStreetMapProvider();

  function doSearchAddress(value) {
    provider
      .search({ query: value })
      .then(function (result) {
        if (result.length > 0) {
          const { x, y, label } = result[0];
          setLocation({ lat: y, lng: x, zoom: 14 });
          setSensor(prevSensor => ({ ...prevSensor, location: y + "," + x }));
        }
      });
  }
  
  const handleInputChange = (event) => {
    console.log("Create.js (119):")
    const { id, value } = event.target;
    console.log(id, value)
    switch (id) {
      case 'location':
        setLocation(getLocationArray(value));
        break;
      case 'city':
        doSearchAddress(value);
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: value,
        }));
        break;
      case 'active':
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: !(value === 'Active'),
        }));
        break;
      default:
        setSensor((prevSensor) => ({
          ...prevSensor,
          [id]: value,
        }));
    }
  };

  const handleImageChange = (e) => {
    setSensor(prevSensor => ({ ...prevSensor, img: e.target.files[0] }));
    setFile(URL.createObjectURL(e.target.files[0]));
  };

  const handleSubmit = event => {
    event.preventDefault();

    SensorRepository.Create().createSensor(sensor)
      .then(async (response) => {
        console.log('Item created:', response.data);
        let qr = searchParams?.get("qr");
        if (qr != null) {
            await QRCodeRepository.Create().sensorCreated(qr, sensor.eui);
        }
        toast("Sensor created!", { type: "success", autoClose: 3000 });
        setTimeout(() => navigate("/sensors/" + sensor.eui + "/edit"), 3000);
      })
      .catch(
        error => {
          toast("Error creating sensor.", { type: "error" });
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );
  };

  const getLocationArray = (locationstr) => {
    const [lat, lng] = String(locationstr).split(',');
    if (Number.isNaN(Number.parseFloat(lat)) || Number.isNaN(Number.parseFloat(lng))) {
      return null;
    } else {
      return { lat: +lat, lng: +lng, zoom: 14 };
    }
  };

  const navigate = useNavigate();

  return (
    <div>
      <Header>New sensor {sensor.eui || ''}</Header>

      <Divider width="100%" color="red" />
      <PageBody>
        <Form noValidate onSubmit={handleSubmit} >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">
            <Accordion defaultActiveKey="0" >
              <Accordion.Item eventKey="0">
                <Accordion.Header>Advanced Settings</Accordion.Header>
                <Accordion.Body>
                  <Form.Group className="mb-3" >
                    <Form.Label>EUI</Form.Label>
                    <Form.Control type="eui" placeholder="XXXXXXXXXXXXXXXX"
                      onChange={handleInputChange}
                      id="eui" required value={sensor.eui} name="eui"
                      maxLength={16}
                      isInvalid={updateError["eui"]}

                    />
                    <Form.Control.Feedback type="invalid">
                      {updateError["eui"]}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="mb-3" >
                    <Form.Label>Join EUI</Form.Label>
                    <Form.Control type="join_eui"
                      onChange={handleInputChange} name="join_eui"
                      maxLength={16}
                      isInvalid={updateError["join_eui"]}

                      placeholder="XXXXXXXXXXXXXXXX" id="join_eui" required value={sensor.join_eui} />
                    <Form.Control.Feedback type="invalid">
                      {updateError["join_eui"]}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="mb-3" >
                    <Form.Label>App Key</Form.Label>
                    <Form.Control type="application_key"
                      onChange={handleInputChange} name="application_key"
                      maxLength={32}
                      isInvalid={updateError["application_key"]}

                      placeholder="XXXXXXXXXXXXXXXXXXXXXXXXXXXXXXXX" id="application_key" required value={sensor.application_key} />
                    <Form.Control.Feedback type="invalid">
                      {updateError["application_key"]}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <SensorTypesSelect changeHandler={handleInputChange} defaultValue={sensor.type}></SensorTypesSelect>
                  <NetworksSelect changeHandler={handleInputChange} defaultValue={null}></NetworksSelect>
                  <NodeSelect changeHandler={handleInputChange} defaultValue={null}></NodeSelect>

                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
            <div className="col-sm-8">
              <p>
                <Button
                  className="form-control"
                  id="active"
                  variant={(sensor.active ? "success" : "danger")}
                  onClick={handleInputChange}
                  value={sensor.active ? "Active" : "Not Active"}
                >{sensor.active ? "Active" : "Not Active"}</Button>
              </p>
              <p>
                <input
                  className="form-control"
                  type="text"
                  placeholder='Name'
                  id='name'
                  value={sensor.name || ''}
                  onChange={handleInputChange}
                  required
                />
              </p>
              <p>
                <textarea
                  className="form-control"
                  rows={3}
                  placeholder='Description'
                  id='description'
                  value={sensor.description || ''}
                  onChange={handleInputChange}
                  required
                />
              </p>

              <div className="row mb-3">
                <label htmlFor="address" className="col-sm-2 col-form-label col-form-label-sm">Address</label>
                <div className="col-sm-10">
                  <DebounceInput
                    className="form-control  form-control-sm"
                    label='address'
                    minLength={2}
                    debounceTimeout={500}
                    type="text"
                    placeholder='address'
                    id='city'
                    value={sensor.city || ''}
                    onChange={handleInputChange}
                  />
                </div>
              </div>
              <p>-or-</p>
              <div className="row mb-3">
                <label htmlFor="location" className="col-sm-3 col-form-label col-form-label-sm">Coordinates:</label>
                <div className="col-sm-9">
                  <DebounceInput
                    className="form-control form-control-sm"
                    label='location'
                    type="text"
                    placeholder='fx. 55.6928608,12.5992622'
                    id='location'
                    value={sensor.location}
                    onChange={handleInputChange}
                    required
                  />
                </div>
              </div>
            </div>
            <div className="col">
              <div className="row mb-3">
                <p>
                  <img src={file} style={{ width: '200px' }} />
                </p>
                <p>
                  <input
                    className="form-control col-3"
                    type="file"
                    id="img"
                    accept="image/png, image/jpeg"
                    onChange={handleImageChange}
                  />
                </p>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col mb-2" >
              <div className="d-flex justify-content-between">
                <div className="col start-0">
                  <Button variant="primary" type="submit" value="Create">Create</Button>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="row flex-grow-1 d-flex flex-col align-items-stretch">
            <LocationMap location={location} setLocation={setLocation} setDevice={setSensor} />
            </div>
          </div>
        </Form>
      </PageBody>
    </div>
  );
}

export default Sensor;
