import Form from 'react-bootstrap/Form';
import React, { useState } from "react";
import NetworkRepository from '../../Repositories/NetworkRepository';
import AxiosRequestConfigBuilder from '../../Extensions/Axios/AxiosRequestConfigBuilder';
import useAsyncEffect from '../../Extensions/React/AsyncEffect/UseAsyncEffect';

const NetworksSelect = ({changeHandler, defaultValue}) => {
  const [networks, setNetworks] = useState<{id, name, url}[]>([]);
  const configBuilder = AxiosRequestConfigBuilder.create()
    .withPaginationLimitAndOffset(1000);

  useAsyncEffect({ fetchAsync: (abortSignal) => {
    async function getNetworks() {
      console.log("getNetworks async - defaultvalue:" + defaultValue)
      NetworkRepository.Create().getNetworks(configBuilder.withAbortSignal(abortSignal).build())
        .then(
          (response) => {
            console.log("Response data" + JSON.stringify(response.data))
            setNetworks(response.data.results);
            console.log(networks)

          },
          (error) => {
            const _event =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            console.log("getNetworks Error:" + JSON.stringify(_event))
            setNetworks([{ id: "N/A", name: "N/A", url: null }])
          }
        )
        .catch(
          (error) => {
            console.log('There has been a problem with your getsites operation: ' + error.message);
          }
        )
        ;
    }

    return getNetworks();
  }, deps: [networks.length]});

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    console.log(id, value)
    changeHandler(event)

  }

  return (
    <>
      <Form.Label htmlFor="networks">Application</Form.Label>

      <Form.Select required id="network" aria-label="Default select example" onChange={handleInputChange} value={defaultValue}>
      <option value="">Select</option>

      {networks.map((network) => {
        return (
          <option key={network.id} value={network.url}>
            {network.name} 
          </option>
        );
      })}
      </Form.Select>
    </>
  );
};

export default NetworksSelect;
