class Pagination {
    pageIndex: number = 0;
    pageSize: number = 10;

    constructor(size: number = 10, page: number = 0) {
        this.pageSize = size;
        this.pageIndex = page;
    }

    getOffset() {
        return this.pageIndex * this.pageSize;
    }
}

export default Pagination;
