
import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import L from 'leaflet';
import icon from 'leaflet/dist/images/marker-icon.png';
import iconShadow from 'leaflet/dist/images/marker-shadow.png';

let DefaultIcon = L.icon({
    iconUrl: icon,
    shadowUrl: iconShadow,
    iconAnchor: [12, 41],
    iconSize: [25, 41],
});
L.Marker.prototype.options.icon = DefaultIcon;
L.Icon.Default.imagePath = '/images/';

const RecenterAutomatically = ({ location }) => {
    const map = useMap();
    useEffect(() => {
      map.setView([location.lat, location.lng], location.zoom ?? map.getZoom());
    }, [location]);
    return null;
};
  
const LocationMap = ({location, setLocation, setDevice, isDraggable = true}) => {
    const DENMARK_COORDS = { lat: 56.26392, lng: 9.501785, zoom: 6 };
    const showLocation = location ?? DENMARK_COORDS;

    const getLocationString = (location) => {
        return location.lat + ", " + location.lng;
    };
    
    
    function DraggableMarker({ isDraggable }) {
        const [draggable, setDraggable] = useState(isDraggable);
        const markerRef = useRef(null);
    
        const eventHandlers = useMemo(
          () => ({
            dragend() {
              const marker = markerRef.current;
              if (marker != null) {
                setLocation(marker.getLatLng());
                setDevice(prevDevice => ({ ...prevDevice, location: getLocationString(marker.getLatLng()) }));
              }
            },
          }),
          []
        );
    
        const toggleDraggable = useCallback(() => {
          setDraggable((d) => !d);
        }, []);
    
        return (
          <Marker
            draggable={draggable}
            eventHandlers={eventHandlers}
            position={showLocation}
            opacity={location == null ? .5 : 1}
            ref={markerRef}>
            <Popup minWidth={90}>
              <span onClick={toggleDraggable}>
                {draggable ? 'Marker is draggable' : 'Click here to make marker draggable'}
              </span>
            </Popup>
          </Marker>
        );
    }
    
    return(
      <div className="map d-flex" id="map">
        <MapContainer center={showLocation} zoom={6} scrollWheelZoom={true} >
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
          />
          <DraggableMarker isDraggable={isDraggable} />
          <RecenterAutomatically location={showLocation} />
        </MapContainer>
      </div>
    )
}

export default LocationMap;
