import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUser } from '@fortawesome/free-solid-svg-icons';

const UserPart = ({username, isDesktopOrLaptop}) => {
    if (isDesktopOrLaptop) {
        return <>{username} <FontAwesomeIcon icon={faUser} title={username} /></>
    }

    return <><FontAwesomeIcon icon={faUser} title={username} /></>
}

export default UserPart
