import React from "react";
import { Routes, Route } from "react-router-dom";
import Simcards from './Simcards'
import SimcardsEdit from './Edit';
import SimcardsCreate from './Create';
import Redirect from "../../Extensions/React/ReactRouter/Redirect";

const SimcardsRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Simcards/>} />
            <Route path="/create/" element={<SimcardsCreate/>} />
            <Route path="/:iccid/*">
                <Route path="edit" element={<SimcardsEdit/>} />
            </Route>

            { /* deprecated */ }
            <Route path="/edit/:iccid" element={<Redirect to={"/simcards/:iccid/edit"} />} />
        </Routes>
    );
}

export default SimcardsRoutes;
