import React, { useState } from 'react';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from "react-router-dom";
import {
    faUser,
    faBuilding,
    faTemperatureLow,
    faWindowRestore,
    faDatabase,
    faDollarSign,
    faPhone,
    faPowerOff,
    faDna,
    faTowerCell,
    faUsers,
    faDiagramProject,
    faSimCard,
    faQrcode
} from '@fortawesome/free-solid-svg-icons';
import Divider from './Divider';
import { redirect } from "react-router-dom";
import { useMediaQuery } from 'react-responsive';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import logo from '../../public/logo192.png';



const SamplSideBar = ({initialCollapsed, setToken, toggled, onClick}) => {    
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-width: 800px)'
    })

    const [collapsed, setCollapsed] = useState(initialCollapsed);
    const handleLogout = () => {
        console.log('REMOVING TOKEN AND REDIRECTING TO /');
        setToken(false);
        return redirect('/');
    };

    const closeMenu = () => onClick();

    const FaMenuItem = ({to, name, icon}) => {
        return <MenuItem component={<Link to={to} onClick={closeMenu} className="link" />} icon={<FontAwesomeIcon icon={icon} />}> {name} </MenuItem>
    }

    return (
        <Sidebar
            className="Sidebar"
            backgroundColor="lightgrey"
            toggled={toggled || isDesktopOrLaptop}
            //breakPoint={isDesktopOrLaptop ? "" : "all"}
            //breakPoint="lg"
            customBreakPoint="800px"
            collapsed={collapsed}
            onBackdropClick={closeMenu}>
            <Menu>
                <MenuItem className="SidebarHeader"  
                    component={<Link to="Dashboard" className="link" onClick={closeMenu} />}
                >
                    <Row>
                        <Col className='LogoPlacement'><img style={{ width: '30px',verticalAlign:'middle',marginBottom:'7px',marginRight:'5px' }} src={logo} alt={"logo"} />
                            SAMPL
                        </Col>
                    </Row>
                </MenuItem>
                
                <Divider />

                <FaMenuItem to="accounts" name="Accounts" icon={faBuilding} />
                <FaMenuItem to="users" name="Users" icon={faUser} />
                <FaMenuItem to="tenants" name="Tenants" icon={faUsers} />
                <FaMenuItem to="qr/codes" name="QR" icon={faQrcode} />
                <MenuItem component={<Link to="simcards" className="link" />} icon={<FontAwesomeIcon icon={faSimCard} />}> Simcards </MenuItem>

                <FaMenuItem to="gateways" name="Gateways" icon={faTowerCell} />
                <FaMenuItem to="sensors" name="Sensors" icon={faTemperatureLow} />
                <FaMenuItem to="sensortypes" name="Sensor Types" icon={faDna} />
                <FaMenuItem to="applications" name="Applications" icon={faWindowRestore} />
                <FaMenuItem to="flows" name="Flows" icon={faDiagramProject} />
                <FaMenuItem to="databases" name="Databases" icon={faDatabase} />
                <FaMenuItem to="billing" name="Billing" icon={faDollarSign} />
                <FaMenuItem to="support" name="Support" icon={faPhone} />

                <MenuItem
                    component={<div onClick={() => handleLogout()}>Logout</div>}
                    icon={<FontAwesomeIcon icon={faPowerOff} />}> Logout </MenuItem>
            </Menu>
        </Sidebar>
    );
}

SamplSideBar.propTypes = {
    setToken: PropTypes.func.isRequired
};

export default SamplSideBar;
