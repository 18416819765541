import React, { useEffect,useState } from 'react';
import Divider from "./Divider";
import Header from "./Header";
import PageBody from "./PageBody";

const Support = () => {
  const [count, setCount] = useState(0);
  function handleLinkClick() {
    // Trigger the event in the "freescout-w" div (assuming the div has an id of "freescout-w")
    const freescoutDiv = document.getElementById("fsw-btn");
    if (freescoutDiv) {
      // You can trigger the event or manipulate the div as needed
      freescoutDiv.dispatchEvent(new Event("click"));
    }
  }

  useEffect(() => {
    // Attach the handleLinkClick function to the link's onClick event
    const link = document.getElementById("your-link-id");
    if (link) {
      link.addEventListener("click", handleLinkClick);
    }
  }, []); // The empty dependency array ensures this effect runs once
  console.log('count: %d',count);

    return (
      <>
        <Header> Support</Header>
        <Divider></Divider>
        <PageBody>
        <p>You can <a id="your-link-id" href="#">click here </a> to open a ticket.</p>
        <p>You can also open a ticket by sending an email to:</p> <p><a href="mailto:support@sensesolutions.dk">support@sensesolutions.dk</a></p>
        </PageBody>
      </>
    );
};

export default Support;
