import React, { useMemo } from "react";
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/Button'
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";
import { useMediaQuery } from 'react-responsive'
import {Row,Col} from 'react-bootstrap'
import ShowLastSeen from "./ShowLastSeen";
import GatewayRepository from "../../Repositories/GatewayRepository";
import EntityListTableView from "../Common/EntityListTableView";
import { MRT_ColumnDef } from "material-react-table";

const Gateways = ({ ...props }) => {
    const isDesktopOrLaptop = useMediaQuery({ query: '(min-width: 1224px)' });

    const columns = useMemo(
        (): MRT_ColumnDef<object, any>[] => [
            {
                accessorKey: 'active',
                header: 'Active',
                size: 110,
                Cell: ({ cell }) => (
                    <div>{(cell.getValue() ? "Yes" : "No")}</div>
                ),
                filterFn: 'equals',
                filterSelectOptions: ['True', 'False'],
                filterVariant: 'select',
            },
            {
                accessorKey: 'eui',
                header: 'EUI',
                Cell: ({ cell }) => (
                    <Link to={`/gateways/${cell.getValue()}/edit`}>{cell.getValue()}</Link>
                ),
            },
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                accessorKey: 'city',
                header: 'Address',
                enableSorting: true,
            },
            {
                accessorKey: 'description',
                header: 'Description',
                enableSorting: false,
            },
            {
                id: 'type__name',
                accessorKey: 'type__name',
                header: 'Type',
                enableSorting: false // not available in backend atm
            },
            {
                id: 'lorawan_network__name',
                accessorKey: 'lorawan_network_name',
                header: 'Lorawan Network',
                enableSorting: false,
            },
            {
                id: 'node__name',
                accessorKey: 'node__name',
                header: 'Owner',
                enableSorting: false // not available in backend atm
            },
            {
                accessorKey: 'eui',
                id: 'lastSeen',
                header: 'Status',
                enableSorting: false,
                Cell: ({ cell }) =>
                    <ShowLastSeen eui={cell.getValue()} />
            },
        ],
        [],
    );

    return (
        <div>
            <Header>Gateways</Header>

            <Divider />
            <PageBody>
                <EntityListTableView
                    entityNamePlural="Gateways"
                    entityFetch={(config) => GatewayRepository.Create().getGatewaysList(config)}
                    columns={columns}
                    columnVisibility={{
                        "name": isDesktopOrLaptop,
                        "city": isDesktopOrLaptop,
                        "description": isDesktopOrLaptop,
                        "node__name": false
                    }}
                    getRowId={(row) => row.eui}
                />
                <br />

                    <Row>
                         <Col className=" mb-2" >
                            <div className="d-flex justify-content-between">
                                <Col className="start-0">
                                    <Link to="/gateways/create/"><Button variant="primary">Create New</Button></Link>
                                </Col>
                                <Col className="midddle-0">
                                    <Link to="/qr/codes/create">
                                        <Button variant="primary">
                                            Create QRcodes
                                        </Button>
                                    </Link>
                                </Col>
                            </div>
                        </Col>
                    </Row>
            </PageBody>
        </div>
    );
}

export default Gateways;
