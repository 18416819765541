export interface SkipProperty {
  (key: string, value: any): boolean;
}

class ObjectFormDataMapper {
  static SkipProperty(propertyName: string): SkipProperty {
    return (k, _) => k === propertyName;
  }
  static SkipImagePropertyWhenNotAFile(propertyName: string): SkipProperty {
    return (k, v) => k === propertyName && !(v instanceof File);
  }

  map<D extends object>(
    o?: D | undefined,
    skips: SkipProperty[] = []
  ): FormData | undefined {
    if (o === undefined) {
      return undefined;
    }

    if (o instanceof FormData) {
      return o;
    }

    const formData = new FormData();
    Object.entries(o).forEach((property) => {
      if (property) {
        const [key, value] = property;
        let skip = false;
        skips.forEach((skipPredicate) => {
          if (skipPredicate(key, value)) {
            skip = true;
          }
        });

        if (skip) {
          console.log("skip:", key, value);
          return;
        }

        formData.append(key, this.mapValue(value));
      }
    });

    return formData;
  }

  private mapValue(value: any) {
    if (value instanceof File) {
      return value;
    }

    if (value === null) {
      return ""; // otherwise "null" will get passed
    }

    if (typeof value === "object") {
      return JSON.stringify(value);
    }

    return value;
  }
}

export default ObjectFormDataMapper;
