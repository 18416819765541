import { DependencyList, useEffect, useState } from "react";
import AsyncEffectClosure from "./AsyncEffectClosure";
import asyncEffect from "./AsyncEffect";
import { AxiosError } from "axios";

export type AsyncEffectState = {
    isLoading: boolean,
    error?: AxiosError
}

const useAsyncEffect = (
    {fetchAsync, cleanup, deps}: 
    {fetchAsync: AsyncEffectClosure, cleanup?: () => void, deps?: DependencyList}
): AsyncEffectState => {
    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState<AxiosError>(null);

    useEffect(() => asyncEffect(fetchAsync, setIsLoading, setError, cleanup), deps);
    return { isLoading, error };
}

export default useAsyncEffect;
