import React, { useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Divider from "../Divider";
import PageBody from "../PageBody";
import Button from 'react-bootstrap/Button';
import QRCodeRepository from "../../Repositories/QRCodeRepository";
import { handleApiError } from "../functions/apiErrorHandling";
import { Scanner } from "@yudiel/react-qr-scanner";
import { Alert, Card, InputGroup, Modal, Table } from "react-bootstrap";
import SensorRepository from "../../Repositories/SensorRepository";
import GatewayRepository from "../../Repositories/GatewayRepository";
import { toast } from 'react-toastify';

const QRScanRegisterForm = ({qr}) => {
    const [updateError, setUpdateError] = useState("");
    const [errorMsg, setErrorMsg] = useState(null);
    const [scanResult, setScanResult] = useState([]);
    const [locked, setLocked] = useState(false);
    const navigate = useNavigate();

    const [showScanModal, setShowScanModal] = useState(false);
    const handleScanModalClose = () => {
        setShowScanModal(false);
        setErrorMsg(null);
    }
    const handleScanModalShow = () => {
        setLocked(false);
        setShowScanModal(true);
    }

    const register = (deviceType, deviceId) => {
        QRCodeRepository.Create().register(qr, deviceType, deviceId)
            .then((response) => {
                console.log("Item updated:", response.data);
            })
            .catch((error) => {
                console.error(error);
                handleApiError(error, setUpdateError, setErrorMsg);
            });
    };

    const extractEUIs = (s) => {
        const euiWithoutColons = /(?:^|[^0-9a-z])([0-9a-f]{16})(?![0-9a-z])/;
        const euiWithColons = /(?:^|[^0-9a-z:])((?:[0-9a-z]{2}:){7}[0-9a-z]{2})(?![0-9a-z:])/;
        const euiRegex = new RegExp("(?:" + euiWithoutColons.source + ")|(?:" + euiWithColons.source + ")", "igm");
        
        const matches = [...s.matchAll(euiRegex)];
        const euis = matches
            .map(m => m[1] || m[2])
            .map(m => m.replaceAll(":", ""));
        return euis;
    }

    const deviceTypeMap = {
        "sensor": 2,
        "gateway": 3
    };

    const handleScan = async (value) => {
        if (! locked) {
            setLocked(true);
            if (! await _handleScan(value)) {
                setLocked(false);
            }
        }
    }

    const _handleScan = async (value) => {
        console.log({ qr: value });        
        value = value.map(qr => qr["rawValue"]).join('//');
        console.log("RAW QR: " + value);
        
        const euis = extractEUIs(value);
        console.log("Extracted: " + JSON.stringify(euis));
        if (euis.length == 0) {
            console.log("Error: no EUI found in scanned QR: " + value);
            setErrorMsg("No EUI found in scanned QR: " + value);
            return false;
        }

        let devices = [];
        for (let eui of euis) {
            let device = await findDeviceInBackend(eui);
            if (device != null) {
                devices.push(device);
            }
        }

        if (devices.length == 1) {
            registerDevice(qr, devices[0]);
            return false;
        }

        if (devices.length > 1) {
            console.log("Error: more than 1 device found in scanned QR: " + value);
            setErrorMsg("More than 1 device found in scanned QR: " + value);
            return false;
        }

        setScanResult(euis.map(e => new ScanItem(e, null)));
        handleScanModalClose();
        return true;
    };

    const handleWarningClose = () => {
        setErrorMsg(null);
    };

    const registerDevice = (qr, device) => {
        console.log("Device found: " + JSON.stringify(device));
        console.log("Register: " + device.eui);
        handleScanModalClose();
        register(device.type, device.eui)
        toast("QR linked to device - redirecting...", { type: "success", autoClose: 3000 });
        setTimeout(() => navigate("/qr/" + qr), 3000);
    }
    
    const findDeviceInBackend = async (eui) => {
        let device = await SensorRepository.Create().getByEui(eui).then(d => d == null ? null : ({ eui: d.eui, type: deviceTypeMap["sensor"], device: d }));
        if (device == null) {
            device = await GatewayRepository.Create().getByEui(eui).then(d => d == null ? null : ({ eui: d.eui, type: deviceTypeMap["gateway"], device: d }));
        }
    
        return device;
    }

    const ScanResultItem = ({item}) => (
        <tr>
            <td>{item.eui}</td>
            <td>
            <InputGroup size="sm">
                <Link to={`/sensors/create/?eui=${item.eui}&qr=${qr}`} ><Button variant="outline-secondary" size="sm">Sensor</Button></Link>
                <Link to={`/gateways/create/?eui=${item.eui}&qr=${qr}`} ><Button variant="outline-secondary" size="sm">Gateway</Button></Link>
            </InputGroup>
            </td>
        </tr>
    );

    const ScanResults = () => {
        if (scanResult?.length > 0) {
            return (
                <>
                <br/>
                <br/>
                <Card>
                    <Card.Header>Scan found these unknown EUIs</Card.Header>
                    <Card.Body>
                        <Table striped bordered hover size="sm">
                            <thead>
                                <tr><th>EUI</th><th>Create Device</th></tr>
                            </thead>
                            <tbody>
                                {scanResult.map((item) => <ScanResultItem key={item.eui} item={item} />)}
                            </tbody>
                        </Table>
                    </Card.Body>
                </Card>
                </>
            );
        }
    }

    return (
        <>
            <Divider width="100%" />
            <PageBody>
                <Button variant="primary" value="Scan" onClick={handleScanModalShow} >Scan Vendor QR</Button>
                <ScanResults />

                <Modal show={showScanModal} onHide={handleScanModalClose}>
                      <Modal.Header closeButton>
                        <Modal.Title>Scan Vendor Device QR code</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>
                        <Scanner 
                            onScan={handleScan}
                            scanDelay={1000}
                            formats={[
                                "linear_codes",
                                "matrix_codes"
                            ]} />
                        <br/>
                        <Alert variant="warning" show={errorMsg} dismissible onClose={handleWarningClose} >{errorMsg}</Alert>
                      </Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleScanModalClose}>
                          Cancel
                        </Button>
                      </Modal.Footer>
                    </Modal>

            </PageBody>
        </>
    );
};

export default QRScanRegisterForm;

class ScanItem {
    eui;
    device;

    constructor(eui, device) {
        this.eui = eui;
        this.device = device;
    }
}
