import React from "react";
import { Routes, Route } from "react-router-dom";
import Tenants from './Tenants';
import TenantEdit from './Edit';
import TenantCreate from './Create';
import Redirect from "../../Extensions/React/ReactRouter/Redirect";

const TenantRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Tenants/>} />
            <Route path="/create/" element={<TenantCreate/>} />
            <Route path="/:tenantId/*">
                <Route path="edit" element={<TenantEdit/>} />
            </Route>

            { /* deprecated */ }
            <Route path="/edit/:tenantId" element={<Redirect to={"/tenants/:tenantId/edit"} />} />
        </Routes>
    );
}

export default TenantRoutes;
