import React, { useEffect, useState } from "react";
import { useParams, useLocation } from 'react-router-dom';
import Form from 'react-bootstrap/Form';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import NodeSelect from "../Common/NodeSelect";
import SimcardRepository from '../../Repositories/SimcardRepository';
import { Modal } from "react-bootstrap";
import { handleApiError } from "../functions/apiErrorHandling";
import MobileNetworkSelect from "./MobileNetworkSelect";

type Simcard = {
  iccid, pincode, pukcode, note, active, MobileNetwork, node
}

const defaultSimcard: Simcard = {iccid: null, pincode: null, pukcode: null, note: null, active: null, MobileNetwork: null, node: null};

const SimcardEdit = () => {
  const [simcard, setSimcard] = useState<Simcard>(defaultSimcard);
  var { iccid } = useParams();
  const [updateError, setUpdateError] = useState("");
  const [fieldErrors, setFieldErrors] = useState({});
  const [showDelete, setShowDelete] = useState(false);

  const handleCloseDelete = () => setShowDelete(false);
  const handleShowDelete = () => setShowDelete(true);

  useEffect(() => {
    async function getSimcard(iccid) {
      try {
        const response = await SimcardRepository.Create().getSimcard(iccid);
        setSimcard(response.data);
        console.log(response.data)
      } catch (error) {
        console.log("getSensor Error: " + JSON.stringify(error));
        setSimcard(defaultSimcard);
      }
    }
    console.log("SimcardId=" + iccid)
    getSimcard(iccid);
  }, [iccid]);


  const handleInputChange = (event) => {
    const { id, value } = event.target;
    console.log(id, value)
    switch (id) {
      case 'active':
        setSimcard((prevSimcard) => ({
            ...prevSimcard,
            [id]: !(value === 'Active'),
        }));
        break;
      default:
        setSimcard((prevSimcard) => ({
          ...prevSimcard,
          [id]: value,
        }));
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    console.log(simcard);
    SimcardRepository.Create().updateSimcard(simcard)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError, setFieldErrors)
        }
      );
  };

  const deleteConfirmation = (event) => {
    event.preventDefault();
    handleDelete(event);
    handleCloseDelete();
  }

  const handleDelete = (event) => {
    event.preventDefault();
    SimcardRepository.Create().deleteSimcard(simcard.iccid)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.log(error);
          console.error("Error" + JSON.stringify(error));
          handleApiError(error, setUpdateError, setFieldErrors)
          console.log(updateError,fieldErrors)
        }
      );
  }

  const navigate = useNavigate();
  return (
    <div>
      <Header>Editing: {simcard.iccid || 'Undefined'}</Header>

      <Divider width="100%" />
      <PageBody>
        <Form onSubmit={handleSubmit} noValidate >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">
          <Form.Group>
                  <Form.Label>Iccid
                  </Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder='Iccid'
                    id='iccid'
                    value={simcard.iccid || ''}
                    isInvalid={fieldErrors["iccid"]}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors["iccid"]}
                  </Form.Control.Feedback>
                </Form.Group>

                  <MobileNetworkSelect changeHandler={handleInputChange} defaultValue={simcard.MobileNetwork} updateError={fieldErrors}></MobileNetworkSelect>
                  <NodeSelect changeHandler={handleInputChange} defaultValue={simcard.node} updateError={fieldErrors}></NodeSelect>

                  <Form.Group>
                  <Form.Label>Pincode
                  </Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder='Pincode'
                    id='pincode'
                    value={simcard.pincode || ''}
                    isInvalid={fieldErrors["pincode"]}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors["version"]}
                  </Form.Control.Feedback>
                </Form.Group>

                  <Form.Group>
                  <Form.Label>Pukcode
                  </Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder='Pukcode'
                    id='pukcode'
                    value={simcard.pukcode || ''}
                    isInvalid={fieldErrors["pukcode"]}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors["version"]}
                  </Form.Control.Feedback>
                </Form.Group>

                  <Form.Group>
                  <Form.Label>Note</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder='Note'
                    id='note'
                    value={simcard.note || ''}
                    isInvalid={fieldErrors["note"]}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {fieldErrors["version"]}
                  </Form.Control.Feedback>
                </Form.Group>
            <div className="col-sm-8">
              <p>
                <Button
                  className="form-control"
                  id="active"
                  variant={(simcard.active ? "success" : "danger")}
                  onClick={handleInputChange}
                  value={simcard.active ? "Active" : "Not Active"}
                >{simcard.active ? "Active" : "Not Active"}</Button>
              </p>

              <p>
                {updateError["non_field_errors"] && (
                  <div className="error">
                    {updateError["non_field_errors"]}
                  </div>
                )}
              </p>

              <div className="row">
                <div className="col mb-2" >
                  <div className="d-flex justify-content-between">
                    <div className="col start-0">
                      <Button variant="primary" type="submit" value="Update" >Update</Button>
                    </div>
                    <div className="col end-0">
                      <Button variant="danger" value="Delete" onClick={handleShowDelete} >Delete</Button>
                    </div>

                    <Modal show={showDelete} onHide={handleCloseDelete}>
                      <Modal.Header closeButton>
                        <Modal.Title>Delete {simcard.iccid}</Modal.Title>
                      </Modal.Header>
                      <Modal.Body>Are you sure you want to delete {simcard.iccid}</Modal.Body>
                      <Modal.Footer>
                        <Button variant="secondary" onClick={handleCloseDelete}>
                          Cancel
                        </Button>
                        <Button variant="primary" onClick={deleteConfirmation}>
                          Yes
                        </Button>
                      </Modal.Footer>
                    </Modal>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>


      </PageBody>
    </div>
  );
}

export default SimcardEdit;
