import React, { useEffect, useState} from "react";
import Form from 'react-bootstrap/Form';
import RecordService from '../../Service/record.service';

const NetworkDisabled = ({sensor}) => {
    function GetObjectName(objectUrl) {
        const [objectName, setObjectName] = useState('');
      
        useEffect(() => {
          RecordService.getUrlName(objectUrl)
            .then((response) => {
              setObjectName(response.data.name);
            })
            .catch((error) => {
              console.error(error);
            });
        }, [objectUrl]);
      
        return objectName;
      };
      
    return (
        <div>
            <Form.Label>Application</Form.Label><Form.Control type="network"
                 name="network"
                disabled
                value={GetObjectName(sensor.network)} />
        </div>
    )
}

export default NetworkDisabled;
