import Form from 'react-bootstrap/Form';
import React from "react";
import NodeSelectField from './NodeSelectField';

const NodeSelect = ({
  changeHandler,
  defaultValue,
  updateError={},
  disabled=false,
  elementId="node",
  valueKey="url",
  label="Owner"
}) => {
  
  return (
    <>
      <Form.Group>
        <Form.Label htmlFor="nodes" hidden={label == null}>{label}</Form.Label>
        <NodeSelectField
          changeHandler={changeHandler}
          defaultValue={defaultValue}
          updateError={updateError}
          disabled={disabled}
          elementId={elementId}
          valueKey={valueKey}
        />
      </Form.Group>
    </>
  );
};

export default NodeSelect;
