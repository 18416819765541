import React from "react";
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";

const Databases = () => {
    return (
      <>
        <Header>Databases</Header>
        <Divider></Divider>
        <PageBody>
        <p>Coming up soon!</p>
        </PageBody>
      </>
    );
};
  
export default Databases;
