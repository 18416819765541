import React from "react";
import { Routes, Route } from "react-router-dom";
import Gateways from './Gateways';
import GatewaysEdit from './Edit';
import GatewaysCreate from './Create';
import Redirect from "../../Extensions/React/ReactRouter/Redirect";

const GatewayRoutes = () => {
    return (
        <Routes>
            <Route path="/" element={<Gateways/>} />
            <Route path="/create/" element={<GatewaysCreate/>} />
            <Route path="/:gatewayId/*">
                <Route path="edit" element={<GatewaysEdit/>} />
            </Route>

            { /* deprecated */ }
            <Route path="/edit/:gatewayId" element={<Redirect to={"/gateways/:gatewayId/edit"} />} />
        </Routes>
    );
}

export default GatewayRoutes;
