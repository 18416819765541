import Form from 'react-bootstrap/Form';
import React, { useEffect, useState } from "react";
import RecordService from '../../Service/record.service';



const LorawanNetworkSelect = ({changeHandler,defaultValue}) => {
  const [lorawanNetworks, setLorawanNetworks] = useState<{id, name, url}[]>([]);
  const urlParams = {
    params:
    {
        'offset': 0,
        'limit': 10000,
    }
  }
  
  useEffect(() => {

    async function getLorawanNetworks() {
      console.log("getLorawanNetwork async")
      RecordService.getLorawanNetworks(urlParams)
        .then(
          (response) => {
            setLorawanNetworks(response.data.results);
            console.log(lorawanNetworks)

          },
          (error) => {
            const _event =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            console.log("getGatewayTypes Error:" + JSON.stringify(_event))
            setLorawanNetworks([{ "id": "N/A", "name": "N/A", url: null }])
          }
        )
        .catch(
          (error) => {
            console.log('There has been a problem with your getLorawanNetwork operation: ' + error.message);
          }
        )
        ;
    }

    getLorawanNetworks();
  }, [lorawanNetworks.length]);

  return (
    <>
      <Form.Label htmlFor="LorawanNetwork">LorawanNetwork</Form.Label>

      <Form.Select required id="lorawan_network" aria-label="Default select example" onChange={changeHandler} value={defaultValue}>
      <option value="">Select</option>
      {lorawanNetworks.map((lorawanNetwork) => {
        return (
          <option key={lorawanNetwork.id} value={lorawanNetwork.url}>
            {lorawanNetwork.name} 
          </option>
        );
      })}
      </Form.Select>
    </>
  );
};

export default LorawanNetworkSelect;
