import React from 'react';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import InputGroup from 'react-bootstrap/InputGroup';
import RecordService, { Downlink } from '../../Service/record.service';
import { useState } from 'react';
import { handleApiError } from '../functions/apiErrorHandling';
import ShowNotification from './ShowNotification';
import ToastContainer from "react-bootstrap/ToastContainer"

const SendDownlink = ({ eui, showDownlink, handleClose }) => {
    const [downlink, setDownlink] = useState<Downlink>({
        payload: null,
        port: null,
        priority: 0,
        confirmed: false
    })
    const [showNotification, setShowNotification] = useState(false);

    const [updateError, setUpdateError] = useState([]);
    const [notificationStatus, setNotificationStatus] = useState("pending");
    
    const handleSendDownlink = (event) => {
        // dostuff
        event.preventDefault();
        setNotificationStatus("Sending")
        setShowNotification(true);
        RecordService.postDownlink(eui, downlink)
            .then(response => {
                console.log('post:', response.data);
                setNotificationStatus("Successful")
                setShowNotification(true);
            })
            .catch(
                error => {
                    console.error(error);
                    handleApiError(error, setUpdateError)
                    setNotificationStatus("Failed: " + error.response.data.detail)
                    setShowNotification(true);
                }
            );
    }

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        console.log(id, value)
        console.log(event)
        switch (id) {
            case "confirmed":
                {
                    console.log("confirmed:" + downlink.confirmed)
                    setDownlink((prevDownlink) => ({
                        ...prevDownlink,
                        [id]: !prevDownlink[id],
                    }));
                    break;
                }
            case "priority":
                {
                    setDownlink((prevDownlink) => ({
                        ...prevDownlink,
                        [id]: value,
                    }));
                    break;
                }


            default:
                {
                    setDownlink((prevDownlink) => ({
                        ...prevDownlink,
                        [id]: value,
                    }));
                }
        }
        console.log(downlink)
    };


    return (
        <><Modal show={showDownlink} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Send Downlink</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form onSubmit={handleSendDownlink}>
                    <InputGroup className="mb-3">
                        <InputGroup.Text>Port</InputGroup.Text>
                        <Form.Control
                            type="text"
                            placeholder="0"
                            autoFocus
                            name="port"
                            id="port"
                            onChange={handleInputChange}
                        />
                        <InputGroup.Text>Confirmed</InputGroup.Text>
                        <InputGroup.Checkbox
                            name="confirmed"
                            type="checkbox"
                            id="confirmed"

                            onChange={handleInputChange} />
                        <InputGroup.Text>Priority</InputGroup.Text>

                        <Form.Select aria-label="Select Priority"
                            id="priority" name="priority" onChange={handleInputChange}>
                            <option value="0">0 (low)</option>
                            <option value="1">1</option>
                            <option value="2">2</option>
                            <option value="3">3 (high)</option>
                        </Form.Select>

                    </InputGroup>
                    <Form.Group className="mb-3">
                        <Form.Label>Downlink (HEX)</Form.Label>
                        <Form.Control
                            as="textarea"
                            rows={3}
                            id="payload"
                            name="payload"
                            placeholder="00 11 22 AA"
                            onChange={handleInputChange} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={handleClose}>
                    Close
                </Button>
                <Button variant="primary" onClick={handleSendDownlink}>
                    Send Downlink
                </Button>
                <ToastContainer
                    className="p-3"
                    position="middle-center"
                    style={{ zIndex: 1 }}>
                    <ShowNotification message={notificationStatus} show={showNotification} setShow={setShowNotification} timeout={3000}></ShowNotification>
                </ToastContainer>
            </Modal.Footer>
        </Modal>
        </>
    );
}


export default SendDownlink;
