import React, { useState } from "react";
import Button from 'react-bootstrap/Button';
import { handleApiError } from "../functions/apiErrorHandling";
import Header from "../Header";
import Divider from "../Divider";
import Form from "react-bootstrap/Form";
import NodeSelect from "../Common/NodeSelect";
import PageBody from "../PageBody";
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import QRCodeRepository from "../../Repositories/QRCodeRepository";

const QRCreateBatch = () => {
    const [batch, setBatch] = useState({ node: 0, amount: 0 });
    const [updateError, setUpdateError] = useState([]);
    const navigate = useNavigate();

    const handleInputChange = (event) => {
        const { id, value } = event.target;
        switch (id) {
            default:
                setBatch((prevGateway) => ({
                    ...prevGateway,
                    [id]: value,
                }));
        }
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        console.log(batch);
        QRCodeRepository.Create()
            .createBatch(batch.node, batch.amount)
            .then(response => {
                console.log(response.data);
                const id = response.data.id;
                toast("Batch created!", { type: "success", autoClose: 3000 });
                setTimeout(() => navigate("/qr/codes/batch/" + id), 3000);
            })
            .catch((error) => {
                console.error(error);
                toast("Error creating batch.", { type: "error" });
                handleApiError(error, setUpdateError);
            });
    };

    return (
        <>
            <Header>Create QR Code Batch</Header>
            <Divider width="100%" color="red" />
            <PageBody>
                <Form noValidate onSubmit={handleSubmit}>
                    <div className="row mb-3 form-group gy-4 gx-4 align-items-center">
                        <div className="col-sm-8">
                            <p>
                                <NodeSelect
                                    changeHandler={handleInputChange}
                                    updateError={updateError}
                                    defaultValue={null}
                                    valueKey="id"
                                />
                            </p>
                            <p>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control
                                    className="form-control"
                                    type="text"
                                    placeholder="Amount"
                                    id="amount"
                                    onChange={handleInputChange}
                                    required
                                    isInvalid={updateError["amount"]}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {updateError["amount"]}
                                </Form.Control.Feedback>
                            </p>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col mb-2">
                            <div className="d-flex justify-content-between">
                                <div className="col start-0">
                                    <Button
                                        variant="primary"
                                        type="submit"
                                        value="Create"
                                    >
                                        Create
                                    </Button>
                                </div>
                            </div>
                        </div>
                    </div>
                </Form>
            </PageBody>
        </>
    );
};

export default QRCreateBatch;
