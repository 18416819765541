import React, { useEffect, useState } from "react";
import Divider from "./Divider";
import Header from "./Header";
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import RecordService, { Credentials } from "../Service/record.service";
import PageBody from "./PageBody";
import { useNavigate } from 'react-router-dom';
import { InputGroup } from "react-bootstrap";
import { handleApiError } from "./functions/apiErrorHandling";
import UserRepository from "../Repositories/UserRepository";

const ChangePassword = () => {
    const [validated, setValidated] = useState(false);
    const [passwordType, setPasswordType] = useState("password");
    const [myForm, setMyForm] = useState(
        {
            new_password1: "",
            new_password2: "",
            current_password: "",
        }
    );
    const [updateError, setUpdateError] = useState("");
    const [fieldErrors, setFieldErrors] = useState({});
    const navigate = useNavigate();
    const [userProfile, setUserProfile] = useState(
        {
            id: null,
            email: "Loading .. ",
            first_name: "Loading ..",
            last_name: "Loading .."
        }
    );

    useEffect(() => {
        async function getUserProfile() {
            UserRepository.Create().getUserProfile()
                .then(
                    (response) => {
                        setUserProfile(response.data);
                    },
                    (error) => {
                        const _event =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                        setUserProfile({
                            id: null,
                            email: "Loading .. ",
                            first_name: "Loading ..",
                            last_name: "Loading .."
                        })
                    }
                )
                .catch(
                    (error) => {
                        console.error('There has been a problem with your getsites operation: ' + error.message);
                    }
                );
        }

        getUserProfile();
    }, []);

    const handleInputChange = (e) => {
        e.preventDefault(); // prevent the default action
        const { id, value } = e.target;
        switch (id) {
            case "new_password1":
            case "new_password2":
                const newMyForm = {
                    ...myForm,
                    [id]: value,
                };
                setMyForm((prevMyForm) => (newMyForm));
                setFieldErrors({
                    ...fieldErrors,
                    ["new_password2"]: newMyForm.new_password1 == newMyForm.new_password2 ? null : "New passwords must be identical.",
                });
                break;
            default:
                setMyForm((prevMyForm) => ({
                    ...prevMyForm,
                    [id]: value,
                }));
                break;
        }
    };

    const togglePasswordType = () => {
        if (passwordType === "password") {
            setPasswordType("text");
            return;
        }
        setPasswordType("password");
    }

    const handleSubmit = event => {
        event.preventDefault();
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();

        }
        const credentials: Credentials = {
            "user_id": userProfile.id,
            "current_password": myForm.current_password,
            "new_password1": myForm.new_password1,
            "new_password2": myForm.new_password2
        }

        RecordService.changePassword(credentials)
            .then(response => {
                setValidated(true);
                navigate(-1);
            })
            .catch(error => {
                console.error(error);
                handleApiError(error, setUpdateError, setFieldErrors);
                setValidated(false)
            });
    };
    
    console.log(updateError)
    return (
        <div>
            <Header> Change Password </Header>
            <Divider />
            <PageBody>
                <Form noValidate validated={validated} onSubmit={handleSubmit}>
                    <p>
                        <InputGroup hasValidation>
                            <InputGroup.Text className="w-25">Current Password</InputGroup.Text>
                            <Form.Control
                                id="current_password"
                                type="password"
                                placeholder="Your current password"
                                value={myForm.current_password}
                                onChange={handleInputChange}
                                isInvalid={fieldErrors["current_password"]}
                                required
                            />
                            <Form.Control.Feedback type="invalid">
                                {updateError["current_password"]}
                            </Form.Control.Feedback>
                        </InputGroup>
                    </p>
                    <p>
                        <InputGroup hasValidation>
                            <InputGroup.Text className="w-25">New Password</InputGroup.Text>
                            <Form.Control
                                id="new_password1"
                                type={passwordType}
                                aria-label="new_password1"
                                placeholder="New Password"
                                value={myForm.new_password1}
                                onChange={handleInputChange}
                                required
                                isInvalid={fieldErrors["new_password1"]}
                            />
                            <Button onClick={togglePasswordType}>
                                {passwordType === "password" ? (
                                    <svg
                                        width="20"
                                        height="17"
                                        fill="currentColor"
                                        className="bi bi-eye-slash-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="m10.79 12.912-1.614-1.615a3.5 3.5 0 0 1-4.474-4.474l-2.06-2.06C.938 6.278 0 8 0 8s3 5.5 8 5.5a7.029 7.029 0 0 0 2.79-.588zM5.21 3.088A7.028 7.028 0 0 1 8 2.5c5 0 8 5.5 8 5.5s-.939 1.721-2.641 3.238l-2.062-2.062a3.5 3.5 0 0 0-4.474-4.474L5.21 3.089z" />
                                        <path d="M5.525 7.646a2.5 2.5 0 0 0 2.829 2.829l-2.83-2.829zm4.95.708-2.829-2.83a2.5 2.5 0 0 1 2.829 2.829zm3.171 6-12-12 .708-.708 12 12-.708.708z" />
                                    </svg>
                                ) : (
                                    <svg
                                        width="20"
                                        height="17"
                                        fill="currentColor"
                                        className="bi bi-eye-fill"
                                        viewBox="0 0 16 16"
                                    >
                                        <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                                        <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                                    </svg>
                                )}
                            </Button>
                            <Form.Control.Feedback type="invalid">
                                {fieldErrors["new_password1"]}
                            </Form.Control.Feedback>
                        </InputGroup>

                    </p>
                    <p>
                        <InputGroup hasValidation>
                            <InputGroup.Text className="w-25">New Password Again</InputGroup.Text>

                            <Form.Control
                                id="new_password2"
                                aria-label="new_password2"
                                type={passwordType}
                                placeholder="Type password Again"
                                value={myForm.new_password2}
                                onChange={handleInputChange}
                                required
                                isInvalid={fieldErrors["new_password2"]}
                            />
                            <Form.Control.Feedback type="invalid">
                                {fieldErrors["new_password2"]}
                            </Form.Control.Feedback>

                        </InputGroup>
                        <Form.Group>
                            <Form.Control type="hidden"
                                isInvalid={fieldErrors["non_field_errors"]} />
                            <Form.Control.Feedback type="invalid">{fieldErrors["non_field_errors"]}</Form.Control.Feedback>
                        </Form.Group>
                    </p>
                    <Button variant="primary" type="submit">
                        Submit
                    </Button>

                </Form>
            </PageBody>
        </div>

    );
};

export default ChangePassword;
