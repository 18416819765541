import React, { useMemo } from "react";
import { Link } from 'react-router-dom';
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";
import RecordService from '../../Service/record.service'
import EntityListTableView from "../Common/EntityListTableView";

const Accounts =() => {    
    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                size: 40,
                Cell: ({ cell }) => (
                    <Link to={`/accounts/${cell.getValue()}/edit`}>{cell.getValue()}</Link>
                ),
            },
            {
                accessorKey: 'name',
                header: 'Name',
            },
        ],
        [],
    );
    
    return (
        <div>
            <Header>Accounts</Header>

            <Divider />
            <PageBody>
                <EntityListTableView
                    entityNamePlural="Accounts"
                    entityFetch={(config) => RecordService.getAccounts(config)}
                    columns={columns}
                    getRowId={(row) => row.id}
                />
            </PageBody>
        </div>
    );
}

export default Accounts;
