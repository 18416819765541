import React, { useMemo } from "react";
import { Link } from 'react-router-dom';
import Divider from "./Divider";
import Header from "./Header";
import PageBody from "./PageBody";
import RecordService from '../Service/record.service'
import EntityListTableView from "./Common/EntityListTableView";

const Flows = () => {
    const columns = useMemo(
        () => [
            {
                accessorKey: 'instance_id',
                header: 'ID',
            },
            {
                accessorKey: 'name',
                header: 'Name',
            },
            {
                accessorKey: 'flow_url',
                header: 'Url',
                Cell: ({ row,cell }) => (
                  <Link to={`${cell.getValue()}`}>{cell.getValue()}</Link>
              ),
            },
            {
                accessorKey: 'node__name',
                header: 'Tenant',
            },
        ],
        [],
    );

    return (
        <div>
            <Header>Flows</Header>

            <Divider />
            <PageBody>
                <EntityListTableView
                    entityNamePlural="Flows"
                    entityFetch={(config) => RecordService.getFlows(config)}
                    columns={columns}
                    getRowId={(row) => row.id}
                />
                <br />
            </PageBody>
        </div>
    );
}

export default Flows;
