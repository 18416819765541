import Form from 'react-bootstrap/Form';
import React, { useState } from "react";
import NodeRepository from '../../Repositories/NodeRepository';
import useAsyncEffect from '../../Extensions/React/AsyncEffect/UseAsyncEffect';
import AxiosRequestConfigBuilder from '../../Extensions/Axios/AxiosRequestConfigBuilder';

const NodeSelectField = ({
  changeHandler,
  defaultValue,
  updateError={},
  disabled=false,
  elementId="node",
  valueKey="url"
}) => {
  const [nodes, setNodes] = useState([{
    id: "",
    path: ""
  }]);
  const configBuilder = AxiosRequestConfigBuilder.create()
    .withPaginationLimitAndOffset(1000);

  useAsyncEffect({ fetchAsync: (abortSignal) => {
    async function getNodes() {
      NodeRepository.Create().getNodes(configBuilder.withAbortSignal(abortSignal).build())
        .then(
          (response) => {
            setNodes(response.data.results.sort((a, b) => a.path.localeCompare(b.path)));
          },
          (error) => {
            const _event =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            console.log("getNodes Error:" + JSON.stringify(_event));
            setNodes([{ "id": "N/A", "path": "N/A" }]);
          }
        )
        .catch(
          (error) => {
            console.log('There has been a problem with your getsites operation: ' + error.message);
          }
        )
        ;
    }

    return getNodes();
  }, deps: [nodes.length]});

  const handleInputChange = (event) => {
    const { id, value } = event.target;
    let node = nodes.find((e) => e.id == value) ?? null;
    changeHandler(event, node)
  }

  return (
    <>
      <Form.Select 
          required id={elementId}
          aria-label="Default select example" 
          onChange={handleInputChange} 
          value={defaultValue} 
          isInvalid={updateError[elementId]}
          disabled={disabled}>
          <option value="">Select</option>
          {nodes.map((node) => {
            return (
              <option key={node.id} value={node[valueKey]}>
                {node.path} 
              </option>
            );
          })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {updateError[elementId]}
      </Form.Control.Feedback>
    </>
  );
};

export default NodeSelectField;
