import { useState } from "react";
import TokenService from "./token.service";

const useToken = () => {
    const getToken = () => {
        const userToken = TokenService.getToken();
        if (typeof userToken !== "undefined") {
            return userToken?.token;
        } else {
            return undefined;
        }
    };

    const [token, setToken] = useState(getToken());
    const saveToken = (userToken) => {
        TokenService.setToken(userToken);
        setToken(userToken?.token);
    };

    return {
        setToken: saveToken,
        token,
    };
};

export default useToken;
