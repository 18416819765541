import { DependencyList, useContext, useState } from "react";
import useAsyncEffect from "../AsyncEffect/UseAsyncEffect";
import usePaginatedListState from "../Pagination/UsePaginatedListState";
import AxiosRequestConfigBuilder from "../../Axios/AxiosRequestConfigBuilder";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import { SortingState } from "@tanstack/table-core";
import { AppContext } from "../../../App";

const useAxiosListEffect = (
    fetchEntities: (config: AxiosRequestConfig) => Promise<AxiosResponse>,
    onPageLoadCallback: () => void = () => {},
    initialSorting?: SortingState,
    deps?: DependencyList
) => {
    const { globalViewNode } = useContext(AppContext);
    const [entities, setEntities] = useState(<object[]>[]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [rowCount, setRowCount] = useState(0);
    const paginatedListState = usePaginatedListState(undefined, initialSorting);
    const { 
        searchFilter,
        columnFilters,
        sorting,
        pagination,
        onPageLoad } = paginatedListState;
    
    const combinedDeps = <unknown[]>[
        columnFilters,
        searchFilter,
        pagination.pageIndex,
        pagination.pageSize,
        sorting,
        globalViewNode,
    ];
    deps?.forEach(d => combinedDeps.push(d));

    const fetchAllRows = () => {
        const config = AxiosRequestConfigBuilder.create()
            .withPaginatedListState(paginatedListState)
            .withPaginationLimitAndOffset(rowCount, 0)
            .withParamsAdded({view_node: globalViewNode?.id})
            //.withAbortSignal(abortSignal)
            .build();

        return fetchEntities(config);
    };

    useAsyncEffect({ fetchAsync: (abortSignal) => {
        async function getEntities() {
            if (!entities.length) {
                setIsLoading(true);
            } else {
                setIsRefetching(true);
            }

            const config = AxiosRequestConfigBuilder.create()
                .withPaginatedListState(paginatedListState)
                .withAbortSignal(abortSignal)
                .withParamsAdded({view_node: globalViewNode?.id})
                .build();

            setIsError(false);
            fetchEntities(config)
                .then(
                    (response) => {
                        onPageLoad();
                        onPageLoadCallback();
                        setEntities(response.data.results);
                        setRowCount(response.data.count);
                    },
                    (error) => {
                        if (error.code == "ERR_CANCELED") {
                            return;
                        }
                        const _event =
                            (error.response && error.response.data) ||
                            error.message ||
                            error.toString();
                        console.log("usePaginatedListEffect.fetch() error:" + JSON.stringify(_event));
                        setIsError(true);
                    }
                )
                .catch(
                    (error) => {
                        setIsError(true);
                        console.error('There has been a problem with your usePaginatedListEffect.fetch() operation: ' + error.message);
                    }
                )
                .finally(() => {
                    setIsLoading(false);
                    setIsRefetching(false);
                });
        }

        return getEntities();
    }, deps: combinedDeps});

    return {
        paginatedListState,
        entities,
        rowCount,
        isError,
        isLoading,
        isRefetching,
        fetchAllRows
    };
}

export default useAxiosListEffect;
