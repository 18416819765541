import React from "react";
import Divider from "./Divider";
import Header from "./Header";
import PageBody from "./PageBody";

const Billing = () => {
    return (
      <>
        <Header>Billing</Header>
        <Divider></Divider>
        <PageBody>
        <p>This is just an example - coming up soon!</p>
        <p>Sensors total: 24</p>
        <p>Sensors active: 22</p>
        <p>Instances: 2</p>
        <p>Databases (gB): 0,2</p>
        <p>Queues: 1</p>


        </PageBody>
      </>
    );
  };

  export default Billing;
