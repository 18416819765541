import React from "react";
import Divider from "../Divider";
import Header from "../Header";
import PageBody from "../PageBody";
import Tile from "./Tile";
import { AxiosRequestConfig, AxiosResponse } from "axios";
import RecordService from "../../Service/record.service";
import UserRepository from "../../Repositories/UserRepository";
import GatewayRepository from "../../Repositories/GatewayRepository";
import SensorRepository from "../../Repositories/SensorRepository";
import NetworkRepository from "../../Repositories/NetworkRepository";
import NodeRepository from "../../Repositories/NodeRepository";
import SimcardRepository from "../../Repositories/SimcardRepository";


type TileConf = {
    name: string,
    link: string,
    fetch: (config: AxiosRequestConfig) => Promise<AxiosResponse>
};

const Dashboard = () => {
    const tiles: TileConf[] = [
        { name: "Accounts", link: "/accounts/", fetch: RecordService.getAccounts },
        { name: "Users", link: "/users/", fetch: UserRepository.Create().getUsers },
        { name: "Gateways", link: "/gateways/", fetch: GatewayRepository.Create().getGatewaysList },
        { name: "Sensors", link: "/sensors/", fetch: SensorRepository.Create().getSensors },
        { name: "Applications", link: "/applications/", fetch: NetworkRepository.Create().getNetworks },
        { name: "Tenants", link: "/tenants/", fetch: NodeRepository.Create().getTenants },
        { name: "Flows", link: "/flows/", fetch: RecordService.getFlows },
        { name: "Simcards", link: "/simcards/", fetch: SimcardRepository.Create().getSimcardsList },
    ].sort((a, b) => a.name.localeCompare(b.name));

    return (
        <div>
            <Header> DASHBOARD </Header>
            <Divider />
            <PageBody>
                <div className="row dashboard-container">
                    { tiles.map((tile) => {
                        return (
                            <Tile entityName={tile.name} link={tile.link} fetchEntities={tile.fetch}></Tile>
                        );
                    })}
                </div>
            </PageBody>
        </div>
    );
};

export default Dashboard;
