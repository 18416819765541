import Form from 'react-bootstrap/Form';
import React, { useEffect, useState } from "react";
import RecordService from '../../Service/record.service';

const VendorSelect = ({ changeHandler, defaultValue, updateError, disabled = false }) => {
  const [vendors, setVendors] = useState<{id, name, url}[]>([]);
  const urlParams = {
    params:
    {
      'offset': 0,
      'limit': 1000,
    }
  }

  useEffect(() => {

    async function getVendors() {
      RecordService.getVendors(urlParams)
        .then(
          (response) => {
            setVendors(response.data.results);
          },
          (error) => {
            const _event =
              (error.response && error.response.data) ||
              error.message ||
              error.toString();
            console.log("getVendors Error:" + JSON.stringify(_event))
            setVendors([{ id: "N/A", name: "N/A", url: null }])
          }
        )
        .catch(
          (error) => {
            console.log('There has been a problem with your getVendors operation: ' + error.message);
          }
        )
        ;
    }

    getVendors();
  }, [vendors.length]);

  const handleInputChange = (event) => {
    changeHandler(event)
  }
  
  return (
    <><Form.Group>
      <Form.Label htmlFor="nodes">Vendor</Form.Label>

      <Form.Select required id="vendor"
        aria-label="Default select example"
        onChange={handleInputChange}
        value={defaultValue}
        disabled={disabled}
        isInvalid={updateError["vendor"]}>
        <option value="">Select</option>
        {vendors.map((vendor) => {
          return (
            <option key={vendor.url} value={vendor.url}>
              {vendor.name}
            </option>
          );
        })}
      </Form.Select>
      <Form.Control.Feedback type="invalid">
        {updateError["vendor"]}
      </Form.Control.Feedback>
    </Form.Group>
    </>
  );
};

export default VendorSelect;
