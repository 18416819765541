import React, { useState } from "react";
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
import Header from "../Header";
import Divider from "../Divider";
import PageBody from "../PageBody";
import { useNavigate } from 'react-router-dom';
import Button from 'react-bootstrap/Button';
import Editor from "@monaco-editor/react";
import VendorSelect from "./VendorSelect";
import NodeSelect from "../Common/NodeSelect";
import { handleApiError } from "../functions/apiErrorHandling";
import SensorTypeRepository from "../../Repositories/SensorTypeRepository";

const SensorTypeCreate = () => {
  const [sensorType, setSensorType] = useState({
    name: undefined,
    model: undefined,
    version: undefined,
    vendor: undefined,
    node: undefined,
    decoder : '\
function Decoder(bytes, port) {\n\
\n\
  function hex(bytes, separator) {\n\
    return bytes.map(function (b)  {\n\
        return ("" + b.toString(16)).padStart(2, \'0\');\n\
    }).join(separator || "");\n\
  }\n\
\n\
  var decoded = {};\n\
  decoded.debug = {\n\
    payload: hex(bytes),\n\
    length: bytes.length,\n\
    port: port,\n\
    server_time: new Date().toISOString()\n\
  };\n\
\n\
  return (decoded);\n\
\n\
}'  
  });
  const [updateError, setUpdateError] = useState([]);
  
  const handleInputChange = (event) => {
    const { id, value } = event.target;
    switch (id) {
      default:
        setSensorType((prevSensorType) => ({
          ...prevSensorType,
          [id]: value,
        }));
    }
  };

  const handleSubmit = event => {
    event.preventDefault();
    console.log(sensorType);
    SensorTypeRepository.Create().createSensorType(sensorType)
      .then(response => {
        console.log('Item updated:', response.data);
        navigate(-1);
      })
      .catch(
        error => {
          console.error(error);
          handleApiError(error, setUpdateError)
        }
      );


  };

  const handleEditorChange = (value, event) => {
    setSensorType((prevSensorType) => ({
      ...prevSensorType,
      ["decoder"]: value,
    }));
  }

  // setup
  const navigate = useNavigate();

  return (
    <div>
      <Header>Create Sensortype</Header>

      <Divider width="100%" />
      <PageBody>
        <Form onSubmit={handleSubmit} noValidate >
          <div className="row mb-3 form-group gy-4 gx-4 align-items-center">

            <div className="col-sm-8">
              <p>
                <Form.Group>
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder='Name'
                    id='name'
                    value={sensorType.name}
                    isInvalid={updateError["name"]}

                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["name"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </p>
              <p>

                <VendorSelect changeHandler={handleInputChange} defaultValue={sensorType.vendor} updateError={updateError}></VendorSelect>
              </p>
              <p>
                <NodeSelect changeHandler={handleInputChange} defaultValue={sensorType.node} updateError={updateError}></NodeSelect>

              </p>
              <p>
                <Form.Group>
                  <Form.Label>Model</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder='Model'
                    isInvalid={updateError["model"]}
                    id='model'
                    value={sensorType.model}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["model"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </p>
              <p>
                <Form.Group>
                  <Form.Label>Version</Form.Label>
                  <Form.Control
                    className="form-control"
                    type="text"
                    placeholder='Version'
                    id='version'
                    value={sensorType.version || ''}
                    isInvalid={updateError["version"]}
                    onChange={handleInputChange}
                    required
                  />
                  <Form.Control.Feedback type="invalid">
                    {updateError["version"]}
                  </Form.Control.Feedback>
                </Form.Group>
              </p>
              <p>

                <Form.Label>Decoder</Form.Label>
                <Editor
                  height="400px"
                  language="javascript"
                  theme="vs-light"
                  value={sensorType.decoder}
                  onChange={handleEditorChange}
                  options={{
                    inlineSuggest: {
                      enabled: true
                    },
                    fontSize: 16,
                    formatOnType: true,
                    autoClosingBrackets: "always",
                    minimap: { scale: 10 }
                  }}

                />

                {updateError["decoder"] && (
                  <Alert variant="danger" className="mt-3"> 
                    {updateError["decoder"]}
                  </Alert>
                )}
              </p>

              <div className="row">
                <div className="col mb-2" >
                  <div className="d-flex justify-content-between">
                    <div className="col start-0">
                      <Button variant="primary" type="submit" value="Create">Create</Button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Form>


      </PageBody>
    </div>
  );
}

export default SensorTypeCreate;
